import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const RequirementsSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);


    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }

    const handleListEdit = (e, index) => {
        const newList = payload.applicationForm.what_you_need_text.map((item, i) => i === index ? e.target.value : item);

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                what_you_need_text: newList
            }
        });
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.what_you_need_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        what_you_need_active: !payload.applicationForm.what_you_need_active
                    }
                });
            }
        },
    ]

    if (isEditing && isModal) {

        return (
            <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
                <EditableInput
                    className="font-bold text-[22px]"
                    type="text"
                    name="what_you_need_title"
                    id="what_you_need_title"
                    value={payload.applicationForm.what_you_need_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                <ul>
                    { payload.applicationForm.what_you_need_text.map((item, index) => (
                        <li className='flex text-[#6D6D6D] leading-[30px] gap-1 items-start' key={index}>
                            {index + 1}.
                            <EditableInput
                                className={"text-[#6D6D6D] leading-[30px]"}
                                type="text"
                                value={item}
                                onChange={(e) => {
                                    handleListEdit(e, index)
                                }}
                                disabled={!isEditing}
                            />
                        </li>
                    ))}
                </ul>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-2">
                <EditableInput
                    className="font-bold lg:text-[22px] text-[20px]"
                    type="text"
                    name="what_you_need_title"
                    id="what_you_need_title"
                    value={payload.applicationForm.what_you_need_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />

                }
            </div>
            { payload.applicationForm.what_you_need_active ?
            <div className='flex flex-col gap-[12px] w-full max-w-[650px]'>
                <ul>
                    {payload.applicationForm.what_you_need_text.map((item, index) => (
                        <li className='flex text-[#6D6D6D] leading-[30px] gap-1 items-start mb-[2px]' key={index}>
                            {index + 1}.
                            <EditableInput
                                className={"text-[#6D6D6D] leading-[30px]"}
                                type="text"
                                value={item}
                                onChange={(e) => handleListEdit(e, index)}
                                disabled={!isEditing}
                            />
                        </li>
                    ))}
                </ul>
            </div> : null }

        </form>
    )
}
