const base = 'api/quantitative-analysis';

export const quantitativeAnalysis = (instance) => ({
    async getQuantitativeAnalysis() {
        const { data } = await instance.get(`${base}?locationName=FTM%20Online%20Survey&no_limit=true`);
        return data;
    },
    async getInterviewQuestions(locationName) {
        const { data } = await instance.get(`api/quantitative-analysis/?locationName=${locationName}&no_limit=true&sort_by=order&sort_as=asc`);
        return data;
    },
});

