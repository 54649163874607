import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { JobDetailsNav } from '../components/base/JobDetailsNav';
import ContactUsLinkBlock from '../components/parts/ContactUsLinkBlock';
import OptionList from '../components/parts/OptionList';

function DispositionStatus() {

    const [list, setList] = useState([
        {
            title: 'Too far for vehicle owner',
            actionRemove: true
        }, {
            title: 'Too far for public transportation',
            actionRemove: true
        }, {
            title: 'Sensitive to travel',
            actionRemove: true
        }, {
            title: 'Pet issues',
            actionRemove: true
        }, {
            title: 'Client Service issues',
            actionRemove: true
        }, {
            title: 'Physicality issues',
            actionRemove: true
        }, {
            title: 'No show – 1st Interview',
            actionRemove: true
        }, {
            title: 'No response to invitation – 1st Interview',
            actionRemove: true
        }, {
            title: 'New Category...',
            actionRemove: false
        },
    ]);

    const handleSave = () => {
    };

    const handleArchiveJob = () => {
    };

    return (
        <section className="flex flex-col px-11 pt-11 pb-16 rounded-md border border-solid border-zinc-300 max-md:py-[36px] max-md:px-[32px]">
            <h2 className="text-3xl pb-11 font-semibold leading-9 text-zinc-800 max-md:max-w-full max-md:text-[28px]">
                Disposition Status
            </h2>
             <ul>
                {list.map((el, idx) =>
                    <OptionList key={idx} el={el} idx={idx} removeAction={(e) => {
                        e.preventDefault();
                        setList((prev) => [...prev].filter((item, index) => index !== idx));
                    }} />
                )}
            </ul>
            <div className="mb-[32px]">
                <a
                    href="#" className="text-7 font-bold text-[#37B34A] underline hover:opacity-70"
                    onClick={(e) => {
                        e.preventDefault();
                        setList((prev) => [...prev, { title: 'New Category' }]);
                    }}
                >Add a New Category</a>
            </div>
            <div className="flex justify-center items-center px-16 mt-8 text-lg font-bold text-center text-white max-md:px-[0px] max-md:max-w-full">
                <div className="flex gap-5 justify-center max-md:flex-col max-md:w-full">
                    <button
                        onClick={handleSave}
                        className="justify-center items-center hover:opacity-70 px-16 flex h-[52px] whitespace-nowrap bg-green-500 rounded-md max-md:px-5 max-md:w-full"
                    >
                        Save
                    </button>
                    <button
                        onClick={handleArchiveJob}
                        className="justify-center px-9 items-center hover:opacity-70 flex h-[52px] bg-sky-900 rounded-md max-md:px-5 max-md:w-full"
                    >
                        Archive Job
                    </button>
                </div>
            </div>
            <div className="mt-8">
                <ContactUsLinkBlock to={"/SBM02_7_6_1"} hr={false} />
            </div>
        </section>
    );
}


const SBM02_7_6 = () => {
    const [formData, setFormData] = useState({
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. Mauris lobortis condimentum nisi, et fermentum risus luctus eget. Cras scelerisque enim et massa scelerisque in dapibus justo rutrum. Duis sit amet mauris et dui dignissim posuere. Sed quis massa et risus pulvinar rhoncus quis sit amet magna. Vivamus ac rhoncus arcu. \n\nQuisque sit amet tortor nulla, sit amet tempus metus. Vivamus placerat scelerisque sapien vitae aliquet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris massa eros, porttitor sed tempus in, pretium mattis sapien. Praesent fringilla odio vitae est vehicula facilisis. Vestibulum accumsan molestie dictum. Integer nec sodales leo. Phasellus tincidunt massa vel purus malesuada id dapibus tortor iaculis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris interdum, dui eget congue venenatis, libero neque porta quam, nec facilisis enim nisl in tortor. Suspendisse suscipit ligula id ipsum gravida euismod. Duis mollis tempor neque a pulvinar.',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData);
    }
    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "Current Job Details", "Description"]} />
            <div className="flex flex-col gap-11 max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 max-md:pt-[40px] pb-24 max-md:pb-[0px] mx-auto">
                <JobDetailsNav />
                <div className="flex flex-col max-w-[1058px] px-4 max-md:px-[0px] w-full my-10 pb-24 mx-auto max-md:pb-[80px] max-md:mb-[0px]">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                        <div className="text-4xl font-bold text-zinc-800">
                            Let’s Get Started
                        </div>
                        <p className='max-w-[783px] mx-auto'>These details serve as the foundation of your job.</p>
                    </div>
                    <DispositionStatus />
                </div>
            </div>
        </>
    )
}

export default SBM02_7_6
