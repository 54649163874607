import React, {useEffect, useState} from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import {ReactComponent as SearchIcon} from "../assets/img/search-icon-grey.svg";
import {Link, useSearchParams} from 'react-router-dom';
import {crumbsConfig} from "./SBM01_Form/config";
import {useDispatch, useSelector} from 'react-redux';
import {getJobsByCompanyFetch} from '../redux/thunks/jobsThunks';
import {format} from 'date-fns';
import PageLoader from '../components/PageLoader';
import {Country} from 'country-state-city';
import {getUser} from '../redux/slices/applicationSlice';
import {fetchAllIndustries} from '../redux/thunks/companiesThunks';
import {Pagination} from "./SBM01_2";
import {setCompanyJobs, setCompanyJobsClear, setPagination} from "../redux/slices/jobsSlice";

export const JobDetails = ({data}) => {
    const dispatch = useDispatch();
    return (
        <Link
            onClick={() => dispatch(setPagination(null))}
            to={`/SBM01_2/${data.id}`}
            className='flex items-start gap-1'
        >
            <div className='flex items-center justify-center shrink-0 w-9 h-9'>
                <div className="w-2 h-2 bg-sky-900"/>
            </div>
            <div
                className="flex flex-col grow shrink-0 items-start basis-0 w-fit font-semibold leading-9 text-stone-500">
                <div className="self-stretch text-xl leading-9 font-bold text-green-500">
                    {data.jobTitle}
                </div>
                <div className="self-stretch font-normal">{data.city}</div>
                <div className="flex gap-2 whitespace-nowrap">
                    <div className="grow">Date Started:</div>
                    <div className="grow">{format(new Date(data.createdAt), "dd/MM/yyyy")}</div>
                </div>
                <div className="flex gap-2 whitespace-nowrap">
                    <div className="grow">Volume of Applicants:</div>
                    <div>{data.candidatesCount}</div>
                </div>
                <div className="flex gap-2 whitespace-nowrap">
                    <div className="grow"># of Offers:</div>
                    <div className="flex-auto">{"5"}</div>
                </div>
            </div>
        </Link>
    );
};

const SBM01 = () => {
    const dispatch = useDispatch();
    const {companyJobs, isLoading, pagination} = useSelector(state => state.jobs);
    const {industries, isLoading: isIndustriesLoading} = useSelector(state => state.companies);
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useSelector(getUser);

    const [formData, setFormData] = useState({
        industrieId: searchParams.get('industrieId') || '',
        location: searchParams.get('location') || '',
        status: searchParams.get('status') || 'active',
    });


    useEffect(() => {
        dispatch(setCompanyJobsClear())
        setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("status", "active");
            newParams.set("sort_by", "updatedAt");
            newParams.set("sort_as", "desc");
            return newParams;
        })
    }, [])

    const [search, setSearch] = useState(searchParams.get('search') || '');

    const industryOptions = !isIndustriesLoading ? [
        {value: '', label: 'All Departments'},
        ...industries.map(item => ({label: item?.name, value: item?.id}))
    ] : [];

    const locations = [
        {value: '', label: 'All Locations'},
        ...Country.getAllCountries().map(item => ({value: item.name, label: item.name}))
    ];

    const statusOptions = [
        {value: 'active', label: 'Active'},
        {value: 'archived', label: 'Archived'},
    ];

    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set(name, value);
            newParams.delete("page");
            return newParams;
        });
    };

    useEffect(() => {
        const newFormData = {
            industrieId: searchParams.get('industrieId') || '',
            location: searchParams.get('location') || '',
            status: searchParams.get('status') || 'active',
            limit: 1000,
        };
        setFormData(newFormData);
        setSearch(searchParams.get('search') || '');
    }, [searchParams]);

    useEffect(() => {
        dispatch(fetchAllIndustries());
        if (user?.companies?.length && user?.companies[0]?.company?.id && searchParams?.toString()) {
            dispatch(getJobsByCompanyFetch({
                companyId: user.companies[0].company.id,
                filters: `${searchParams.toString()}`
            }));
        }
    }, [user, searchParams]);

    const crumbs = [crumbsConfig.sub, crumbsConfig.jobs];

    if (Array.from(searchParams.entries()).length) {
        crumbs.push(crumbsConfig.search);
    }

    const handlePageChange = (page) => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set("page", page);
            return newParams;
        });
    };

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs}/>
            <section className="flex justify-center flex-auto w-full text-zinc-800">
                <div className="flex flex-col items-center pt-9 pb-20 lg:pt-16 lg:pb-24 w-full max-w-[1432px] px-4">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-10 lg:mb-11'>
                        <div className="lg:text-[40px] text-4xl font-bold lg:leading-[48px] text-zinc-800">
                            Current Job Openings
                        </div>
                    </div>
                    <div
                        className="flex flex-col px-8 py-9 lg:px-[60px] lg:py-[50px] text-lg w-full bg-white rounded-md border border-solid border-zinc-300">
                        <div className="flex max-lg:flex-col gap-[14px] lg:gap-8 w-full text-neutral-400">
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="industrieId"
                                    value={formData.industrieId}
                                    onChange={handleChange}
                                    options={industryOptions}
                                />
                            </div>
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}
                                    options={locations}
                                />
                            </div>
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    options={statusOptions}
                                />
                            </div>
                            <div className="flex items-center max-lg:w-full lg:ml-auto">
                                <div
                                    className="flex items-center flex-auto bg-white rounded-md border border-solid border-zinc-300 outline-none pr-4 mb-[18px]"
                                >
                                    <input
                                        type="search"
                                        className="flex-1 px-4 py-2 rounded-md outline-none h-[52px] lg:h-[54px]"
                                        placeholder="Cleaning Technician"
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                            if (e.target.value.length === 0) {
                                                handleChange({target: {name: "search", value: e.target.value}})
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleChange({target: {name: "search", value: search}})
                                            }
                                        }}
                                        id="search"
                                        name='search'
                                        value={search}
                                    />
                                    <span className="flex cursor-pointer"
                                          onClick={() => handleChange({target: {name: "search", value: search}})}>
                                        <SearchIcon/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="shrink-0 my-9 lg:my-[50px] h-px border border-solid bg-neutral-200 border-neutral-200"/>
                        {
                            !isLoading && companyJobs?.length ?
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-9">
                                    {companyJobs.map(job => (
                                        <JobDetails key={job.id} data={job}/>
                                    ))}
                                </div>
                                : isLoading ? <PageLoader/> :
                                    <h2 className='text-center text-4xl font-bold'>There is no jobs yet...</h2>
                        }
                    </div>
                    {pagination && pagination?.maxPages >= 2 && <Pagination
                        currentPage={pagination.page}
                        totalPages={pagination.maxPages}
                        onPageChange={handlePageChange}
                    />}
                </div>
            </section>
            <Footer hideCopyright={true}/>
        </>
    )
}

export default SBM01;
