import React, {useEffect, useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from "../components/parts/Footer";
import SelectBox from "../components/base/SelectBox";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllIndustries,
    fetchCompanyDemoByIdSupport,
} from "../redux/thunks/companiesThunks";
import {getUser} from "../redux/slices/applicationSlice";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import api from "../api";
import TextareaField from "../components/base/TextareaField";
import {periodsOptions} from "../data/constants";

const SUP1_1_3_1 = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const account = useSelector(getUser)
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        phoneNumber: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        industry: '',
        topHiringChallenges: '',
        title: '',
        features: '',
        price: "",
        period: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [industryOptions, setIndustryOptions] = useState([])
    const [allPlans, setAllPlans] = useState(null);
    const navigate = useNavigate()

    const [plan, setPlan] = useState(null)

    const req = async () => {
        setIsLoading(true)

        const inds = await dispatch(fetchAllIndustries())
        const res = await dispatch(fetchCompanyDemoByIdSupport(id)).unwrap()

        let indsId = ''
        const currentIndustry = +res?.data?.industry

        if (inds?.payload && inds.payload.length > 0) {
            indsId = currentIndustry ? inds.payload.find(el => el?.id === currentIndustry) : ''
            setIndustryOptions([...inds.payload.map(el => ({label: el.name, value: el?.id}))])
        }

        if (res?.data) {

            setFormData(prev => ({
                ...prev,
                companyName: res?.data?.name,
                companySize: res?.data?.companyEmployees,
                jobTitle: res?.data?.jobTitle,
                fullName: res?.data?.fullName,
                phoneNumber: res?.data?.phoneNumber,
                email: res?.data?.email,
                industry: currentIndustry,
            }))
        }
        const plansRes = await api.companies.getAllPricingPlans();
        if (plansRes?.success) {
            setAllPlans(plansRes?.data)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        req()
    }, [account, id]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        const validNumberRegex = /^0$|^(0|[1-9]\d{0,4})([.,]\d{1,3})?$/;

        setFormData((prevState) => ({
            ...prevState,
            [name]: name === "price" && value
                ? validNumberRegex.test(value)
                    ? value
                    : prevState[name]
                : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setErrors({});

        let tempErrors = {};

        let fields = [
            ['title', formData.title],
            ['features', formData.features],
            ['price', formData.price],
            ['period', formData.period],
        ];

        fields.forEach(([key, value]) => {
            if (!value) {
                tempErrors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required`;
            }
        });

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            setIsLoading(false)
            return;
        }

        try {

            const res = await api.companies.getApproveCompanyByIdSupport(id, {
                title: formData.title,
                features: formData.features,
                price: formData.price,
                period: formData.period,
            });

            if (res.success) {
                toast.success("Success")
                navigate(`/SUP1_1_3`)

            } else {
                toast.error(res?.message)
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err);
        }

        setIsLoading(false)
    };

    const changePlan = (id) => {
        const plan = allPlans?.find(plan => plan.id === id)

        setPlan(id)
        setFormData(prev => ({
            ...prev,
            title: plan?.title,
            features: plan?.features,
            price: plan?.price,
            period: plan?.period,
        }))
    };

    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Demo Company Requests']}/>

            <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1240px] w-full m-auto flex-1">
                <div className="flex flex-col justify-center bg-white px-4">
                    {
                        !isLoading ?
                            <section
                                className="flex flex-col justify-between items-center px-8 lg:px-16 py-9 lg:py-[60px] bg-white rounded-md shadow-[0_5px_25px_-10px_rgba(0,0,0,0.15)] lg:mt-[24px]">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2 gap-x-[60px]">
                                        <div>
                                            <InputField
                                                label="Work Email"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="email" name="email" id="email"
                                                placeholder="youremailaddress@mail.com"
                                                disabled
                                                value={formData.email} onChange={handleChange} error={errors.email}
                                            />
                                            <InputField
                                                label="Phone"
                                                disabled
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="phoneNumber" id="phoneNumber" placeholder="Phone Number"
                                                value={formData.phoneNumber} onChange={handleChange}
                                                error={errors.phoneNumber}
                                            />
                                            <InputField
                                                label="Full Name"
                                                disabled
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="fullName" id="fullName" placeholder="Full name"
                                                value={formData?.fullName} onChange={handleChange}
                                                error={errors.fullName}
                                            />
                                            <InputField
                                                label="Company Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="companyName" id="companyName" placeholder="Company name"
                                                value={formData.companyName} onChange={handleChange}
                                                error={errors.companyName}
                                                disabled
                                            />
                                            <InputField
                                                label="Company Size"
                                                disabled
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="Company Size" id="Company Size" placeholder="Company Size"
                                                value={formData?.companySize} onChange={handleChange}
                                                error={errors.companySize}
                                            />
                                            {errors.companySize &&
                                                <span className="text-red-500 mb-[18px]">{errors.companySize}</span>}
                                            <div className="grid lg:grid-cols-2 gap-x-5">
                                                <div>
                                                    <InputField
                                                        label="Job Title"
                                                        inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                        name="jobTitle" id="jobTitle" placeholder="Job title"
                                                        value={formData.jobTitle} onChange={handleChange}
                                                        disabled
                                                    />
                                                    {errors.jobTitle &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.jobTitle}</span>}
                                                </div>
                                                <div>
                                                    <SelectBox
                                                        options={industryOptions}
                                                        label="Industry"
                                                        onSelect={val => {
                                                            setFormData(prevState => ({...prevState, 'industry': val}))
                                                        }}
                                                        className={'!mt-[10px] mb-[18px]'}
                                                        value={formData?.industry} svg={2}
                                                        placeholder={formData?.industry}
                                                        disabled
                                                        labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}

                                                    />
                                                    {errors.industry &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.industry}</span>}
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex flex-col gap-[8px] flex-1 mb-4 ">
                                                <SelectBox
                                                    options={allPlans?.map(plan => ({
                                                        label: plan.title,
                                                        value: plan.id
                                                    })) || []}
                                                    label="Pricing Plan"
                                                    onSelect={val => changePlan(val)}
                                                    className={'!mt-[10px] '}
                                                    value={plan} svg={2}
                                                    labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}
                                                />
                                            </div>
                                            <InputField label={"Title"} name="title"
                                                        id="title"
                                                        className={"border border-solid border-[#DEDED9]"}
                                                        inputBg={"!bg-[#FFFFFF]"}
                                                        value={formData.title} onChange={handleChange}
                                                        error={errors.title}/>
                                            <InputField label={"Price"} name="price"
                                                        id="price"
                                                        type={"number"}
                                                        className={"border border-solid border-[#DEDED9]"}
                                                        inputBg={"!bg-[#FFFFFF]"}
                                                        value={formData.price} onChange={handleChange}
                                                        error={errors.price}/>

                                            <SelectBox options={periodsOptions} label={'Period'}
                                                       onSelect={val => {
                                                           setFormData(prevState => ({...prevState, 'period': val}))
                                                           setErrors(prevState => ({
                                                               ...prevState,
                                                               'period': false
                                                           }));
                                                       }}
                                                       className={'!mt-[10px] !mb-[16px]'}
                                                       value={formData.period} svg={2}
                                                       error={errors.period}
                                                       placeholder={"Choose Plan Period"}
                                                       labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}
                                            />

                                            <TextareaField
                                                wrapperClassName={"mt-5"}
                                                label="Features"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="features" id="features" placeholder="Features"
                                                disabled={isLoading}
                                                value={formData?.features} onChange={handleChange}
                                                error={errors?.features}
                                                className={"!h-[155px]"}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-center items-center max-md:flex-col gap-[22px] mt-[24px]">
                                        <button
                                            className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
                                            Approve company request
                                        </button>
                                    </div>
                                </form>
                            </section>
                            :
                            <div className='text-4xl md:text-5xl font-bold text-sky-950 text-center'>Load
                                information</div>
                    }
                </div>
            </main>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SUP1_1_3_1;
