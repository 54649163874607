import * as React from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";

const SectionTitle = ({ title }) => (
    <section className="mt-5 max-md:max-w-full">
      <h3>{title}</h3>
    </section>
);

// const InputField = ({ id, ariaLabel, placeholder }) => (
//     <div className="shrink-0 mt-4 rounded-md bg-slate-100 h-[54px] max-md:max-w-full">
//       <label htmlFor={id} className="sr-only">{placeholder}</label>
//       <input type="text" id={id} aria-label={ariaLabel} placeholder={placeholder} className="w-full h-full px-4 bg-slate-100" />
//     </div>
// );

const RegistrationForm = () => {
    return (
      <main className="flex flex-col self-end px-14 py-12 mt-8 max-w-full text-lg bg-white rounded-md shadow-lg text-zinc-800 w-[640px] max-md:px-5 mx-auto">
        <form>
          <InputField label="Client Work Email" id="clientEmail" ariaLabel="Client Work Email" placeholder="Enter Client Work Email" />

          <InputField label="First Name" id="firstName" ariaLabel="First Name" placeholder="Enter First Name" />

          <InputField label="Last Name" id="lastName" ariaLabel="Last Name" placeholder="Enter Last Name" />

          <InputField label="Company Name" id="companyName" ariaLabel="Company Name" placeholder="Enter Company Name" />

          <h3 className="mt-[32px] mb-[24px] text-[24px] text-[#002E67] font-[700]">Plan Type Essential Premium Advanced</h3>

          <InputField label="Annual Premium" id="annualPremium" ariaLabel="Annual Premium" placeholder="Enter Annual Premium" />

          <InputField label="1st Installment" id="firstInstallment" ariaLabel="1st Installment" placeholder="Enter 1st Installment" />

          <InputField label="2nd Installment" id="secondInstallment" ariaLabel="2nd Installment" placeholder="Enter 2nd Installment" />

          <button className="flex justify-center items-center p-[18px] mt-8 text-[22px] font-[700] text-center text-white uppercase whitespace-nowrap bg-[#37B34A] rounded-md cursor-pointer w-full mb-[32px]" tabIndex="0" role="button">
            Generate URL of Registration
          </button>

          <InputField label="URL of Registration" id="secondInstallment" ariaLabel="URL of Registration" placeholder="Enter URL of Registration" />
        </form>
      </main>
    );
};

// const FormInputField = ({ id, label }) => (
//   <>
//     <label htmlFor={id} className="text-lg font-bold text-zinc-800 max-md:max-w-full">{label}</label>
//     <input type="text" id={id} className="shrink-0 mt-4 rounded-md bg-slate-100 h-[54px] max-md:max-w-full" />
//   </>
// );

const HOM1_7_1 = () => {
  const breadcrumbItems = [
    { name: 'Home', to: "/", isCurrent: false },
    { name: 'Subscribe', to: "/HOM1_7", isCurrent: false },
    { name: 'Sales Person Charge Setup Form', to: "/HOM1_7_1", isCurrent: true },
  ];

  return (<>
    <Breadcrumbs
      links={breadcrumbItems}
    />
    <RegistrationForm />
	</>)
};

export default HOM1_7_1;
