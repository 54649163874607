import React, { useEffect, useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import Search from "../components/base/Search";
import SelectBox from "../components/base/SelectBox";
import { Footer } from "../components/parts/Footer";
import { useNavigate } from "react-router-dom";
import SupportFilters from "../components/parts/SupportFilters";
import {useDispatch, useSelector} from "react-redux";
import {getSupportSolutionsPages} from "../redux/slices/supportSolutionsSlice";
import {fetchSupportSolutionsPages, fetchSupSolCategories, fetchSupSolTags} from "../redux/thunks/supportThunks";
import {fetchPublicSolutionsPages, fetchPubSolCategories, fetchPubSolTags} from "../redux/thunks/publicThunks";
import {getPublicSolutionsPages} from "../redux/slices/publicSolutionsSlice";

function PostTable({ data }) {
	const navigate = useNavigate()

	const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const getDate = (date) => {
		const d = new Date(date);

		const year = d.getFullYear();
		const month = d.getMonth() + 1;
		const day = d.getDate();

		return `${shortMonths[month]} ${day}, ${year}`
	}
	return (
		<>
			<div className="w-full flex flex-col gap-7 mt-9 lg:hidden">
				{data.map((post, index) => (
					<div className="flex flex-col gap-3" key={post.id + '12'} onClick={() => navigate(`/SUP01_2_1/${post.id}`)}>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Category</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post.category?.name || ''}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Title</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post.name}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Date</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{getDate(post.createdAt)}</div>
						</div>
					</div>
				))}
			</div>
			<div className="overflow-x-auto max-lg:hidden">
				<table className="w-full mt-8 mb-36 sm:mb-10 min-w-max">
					<thead>
						<tr className="text-left">
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Title</th>
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Category</th>
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Date</th>
						</tr>
					</thead>
					<tbody>
						{data.map((post, index) => (
							<tr key={post.id + '11'} className="cursor-pointer" onClick={() => navigate(`/SUP01_2_1/${post.id}`)}>
								<td className="py-[12px] text-[26px] text-[#003578] font-bold pr-[80px] max-w-[400px] text-ellipsis overflow-hidden">{post.name}</td>
								<td className="py-[12px] text-[26px] text-[#003578] font-bold">{post.category?.name || ''}</td>
								<td className="py-[12px] text-[26px] text-[#003578] font-bold">{getDate(post.createdAt)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}



function PostsPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)

	const pages = useSelector(getPublicSolutionsPages)

	const postData = pages ? pages : []
	const [filteredData, setFilteredData] = useState([])

	useEffect(() => {
		const req = async () => {
			setIsLoading(true)
			await dispatch(fetchPubSolCategories())
			await dispatch(fetchPublicSolutionsPages())
			await dispatch(fetchPubSolTags())
			setIsLoading(false)
		}

		if (!pages) {
			req()
		} else {
			setFilteredData(pages)
		}

	}, [pages]);


	useEffect(() => {

	}, [filteredData])
	return (
		<section className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 text-zinc-800 w-full">
			<div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
				<h2 className="lg:text-[40px] text-4xl leading-[44px] text-center lg:leading-[40px] mb-[8px] font-bold text-zinc-800">List
					of Previous Posts</h2>
				<p className="text-[18px] text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
				<div
					className="flex flex-col self-stretch py-9 px-8 lg:px-16 lg:py-12 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
					{/* <div className="flex gap-5 justify-between w-full max-md:flex-col">
						<div className="w-full md:max-w-[256px]">
							<SelectBox options={
								[
									{
										label: 'Maid Service'
									},
									{
										label: 'Maid Service 2'
									},
								]
							} />
						</div>
						<Search parentClassName={"md:max-w-[420px] w-full"} />
					</div> */}
					<SupportFilters data={postData} setData={setFilteredData} category search searchField="title"/>
					<div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full"/>
					{isLoading &&
						<div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>Loading...</div>}
					{
						!isLoading && filteredData.length ?
							<PostTable data={filteredData}/> : null
					}
					{!isLoading && !filteredData.length ?
						<div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No Posts
							yet</div> : null}
				</div>

				<div className="flex justify-center w-full">
					<button onClick={() => navigate(`/SUP01_2_1`)}
							className="md:mt-11 mt-10 min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70">
						Create Public Page
					</button>
				</div>
			</div>
		</section>
	);
}


const SUP01_2 = () => {
	return (
		<>
			<BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Public Solutions Page List']}/>
			<PostsPage/>
			<Footer hideCopyright={true}/>
		</>
	);
};

export default SUP01_2;
