import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';

export const getAllJobRatingsFetch = createAsyncThunk(
    '/getAllJobRatingsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobRatings.getAllJobRatings(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getFsmFetch = createAsyncThunk(
    '/getFsmFetch/',
    async ( { rejectWithValue }) => {
        try {
            const res = await api.jobRatings.getFsm();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const createJobRatingsFetch = createAsyncThunk(
    '/createJobRatingsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobRatings.createJobRatings(payload);
            if (res.success) {
                toast.success(res?.message || "Created")
                return res.data;
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updateJobRatingsFetch = createAsyncThunk(
    '/updateJobRatingsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobRatings.updateJobRatings(payload);
            if (res.success) {
                toast.success(res?.message || "Updated")
                return res.data;
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
export const deleteJobRatingsFetch = createAsyncThunk(
    '/deleteJobRatingsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobRatings.deleteJobRatings(payload);
            if (res.success) {
                toast.success(res?.message || "Deleted")
                return res.data;
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
