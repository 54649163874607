import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import PageLoader from '../components/PageLoader';
import {getJobByIdFetch, getJobByIdParamsFetch} from '../redux/thunks/jobsThunks';


const JobApplicationLayout = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getJobByIdParamsFetch({jobId: id, params: "?fixCr=true"}));
    }, [id, dispatch])

    const {job, isLoading, error} = useSelector(state => state.jobs);
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [formData, setFormData] = useState(() => {
        const answers = localStorage.getItem(`job-${id}-answers`);
        return answers ? JSON.parse(answers) : {
            most_recent_employment_history_answer: [{}],
        }
    });

    useEffect(() => {
        setFormData(prev => ({...prev, source: document?.referrer}))
    }, []);

    console.log("formData", formData)

    return <div className='w-full flex flex-col'>
        {
            error ?
                <p>{error}</p> :
                isLoading || !job ?
                    <PageLoader/> :
                    <Outlet context={[formData, setFormData]}/>
        }
    </div>
};

export default JobApplicationLayout
