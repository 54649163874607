import React, { useEffect, useState } from "react";

import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from "../components/parts/Footer";
import SupportFilters from "../components/parts/SupportFilters";

const SUP01_4_1 = () => {

    const postsData = [
        {
            category: "Home public pages",
            title: "Why EmployJoy",
            date: "Nov 23, 2023",
        },
        {
            category: "Home public pages",
            title: "Solution",
            date: "Oct 1, 2023",
        },
        {
            category: "Home public pages",
            title: "Book A Demo",
            date: "Oct 1, 2023",
        },
        {
            category: "Home public pages",
            title: "Login",
            date: "Oct 1, 2023",
        },
    ];
    const [filteredData, setFilteredData] = useState(postsData)

    return (
        <>
            <BreadCrumbWhite crumbs={['EJ Support', 'Public Home Page List']} />

            <section className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800">
                <div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
                    <h2 className="lg:text-[40px] text-4xl leading-[44px] text-center lg:leading-[40px] mb-[8px] font-bold text-zinc-800">List of Pages</h2>
                    <p className="text-[18px] text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div className="flex flex-col self-stretch py-9 px-8 lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                        {/* <div className="flex gap-5 justify-between w-full max-md:flex-col">
                            <div className="w-full md:max-w-[256px]">
                                <SelectBox options={[
                                    {
                                        label: 'Active'
                                    },
                                    {
                                        label: 'Disable'
                                    },
                                ]} />
                            </div>
                            <Search parentClassName={"md:max-w-[420px] w-full"} />
                        </div> */}
                        <SupportFilters data={postsData} setData={setFilteredData} category search searchField="title" />

                        <div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full" />
                        <div>
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5198d608b8d4543d890f109fb3680ade63939aa1c83a8bfd1a970e1d21cb326?apiKey=e59c4f0875424a15b5751797686f8863&" alt="Placeholder" className="mt-12 w-full aspect-[1.67] max-md:mt-10 max-md:max-w-full" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer hideCopyright={true} />
        </>
    )
}


export default SUP01_4_1
