import React from 'react'
import { Link } from 'react-router-dom'

const ContactUsLinkBlock = ({ to = "/SBM02_2_1", hr = true, setContactFormOpen, topPaste = null }) => {
  return (
    <>
      {
        hr ?
          <hr className="my-9 lg:my-[50px] border-b border-neutral-200" />
          :
          null
      }
      { topPaste }
      <div className='text-center text-lg lg:text-[26px] lg:leading-[30px] italic text-[#666666]'>
        <span>
          { setContactFormOpen ?
            <a
              href="#" className="text-[#37B34A] underline"
              onClick={(e)=>{
                e.preventDefault();
                setContactFormOpen(true);
              }}
            >CONTACT</a> :
            <Link to={to} className="text-[#37B34A] underline">CONTACT</Link>
          } your onboarding specialist if you have any questions
        </span>
      </div>
    </>
  )
}

export default ContactUsLinkBlock
