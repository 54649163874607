import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';

export const getAllComunicationsFetch = createAsyncThunk(
    '/getAllComunicationsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.communications.getAllComunications(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const sendMessageToApplicantFetch = createAsyncThunk(
    '/sendMessageToApplicantFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.communications.sendMessageToApplicant(payload);
            if (res.success) {
                // toast.success(res?.message || "Message sent")
                return res.data;
            } else {
                toast.error(res?.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error( err?.response?.data?.message || err?.message || "Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
