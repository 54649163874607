import React, {useEffect, useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from "../components/parts/Footer";
import SelectBox from "../components/base/SelectBox";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllIndustries,
    fetchCompanyByIdSupport,
    fetchCreateCompanies, fetchDeactivateCompanyByIdSupport,
    fetchUpdateBuildCompanies, fetchUpdateCompanyByIdSupport
} from "../redux/thunks/companiesThunks";
import {getUser} from "../redux/slices/applicationSlice";
import {deactivateUser, updateUserFetch} from "../redux/thunks/applicationThunks";
import ModalTemplate from "../components/base/ModalTemplate";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {format} from "date-fns";
import PrimaryButton from "../components/base/PrimaryButton";
import api from "../api";
import {companySizeOptions} from "../data/constants";


const SUP1_1 = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const account = useSelector(getUser)
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        industry: '',
        topHiringChallenges: '',
        name: '',
        // password: '',
        // cardNumber: '',
        // expirationDate: '',
        // cvv: '',
        // postalCode: '',
        // holderName: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [industryOptions, setIndustryOptions] = useState([])
    const [accInfo, setAccInfo] = useState();
    const [planInfo, setPlanInfo] = useState(null)


    const req = async () => {
        setIsLoading(true)
        const inds = await dispatch(fetchAllIndustries())


        let indsId = ''
        const currentIndustry = account?.company?.industry?.id || account?.company?.industrieId

        if (inds?.payload && inds.payload.length > 0) {
            indsId = currentIndustry ? inds.payload.find(el => el?.id === currentIndustry) : ''
            setIndustryOptions([...inds.payload.map(el => ({label: el.name, value: el?.id}))])
        }

        if (account && !id) {
            setFormData(prev => ({
                ...prev,
                companyName: account?.company?.name,
                companySize: account?.company?.companySize,
                jobTitle: account?.company?.jobTitle,
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                industry: indsId.id,
                holderName: ""
            }))
        }
        if (id) {

            try {
                const res = await dispatch(fetchCompanyByIdSupport(id)).unwrap()
                setAccInfo(res?.data)
                setPlanInfo(res?.data?.companyPlan)

                setFormData(prev => ({
                    ...prev,
                    companyName: res?.data?.name,
                    companySize: res?.data?.companySize,
                    jobTitle: res?.data?.jobTitle,
                    firstName: res?.data?.owner?.firstName,
                    lastName: res?.data?.owner?.lastName,
                    email: res?.data?.owner?.email,
                    industry: res?.data?.industrieId,
                    holderName: ""
                }))
            } catch (e) {
                console.log(e)
                toast.error("You don't have access to this page")
                setIsLoading(false)
                return
            }
        }
        // const plansRes = await api.companies.getAllPricingPlans();
        // if (plansRes?.success) {
        //     setAllPlans(plansRes?.data)
        // }
        setIsLoading(false)
    }

    useEffect(() => {
        req()
    }, [account, id]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setErrors({});

        let tempErrors = {};

        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (!validateEmail(formData.email)) {
            tempErrors.email = 'Invalid email format';
        }
        let fields = [
            ['email', formData.email],
            ['firstName', formData.firstName],
            ['lastName', formData.lastName],
            ['companyName', formData.companyName],
            ['companySize', formData.companySize],
            ['jobTitle', formData.jobTitle],
            ['industry', formData.industry]
        ];


        fields.forEach(([key, value]) => {
            if (!value) {
                tempErrors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required`;
            }
        });

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            setIsLoading(false)
            return;
        }

        if (!id) {
            await dispatch(updateUserFetch({
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName
            }))

            if (account?.company) {
                await dispatch(fetchUpdateBuildCompanies({
                    name: formData.companyName,
                    jobTitle: formData.jobTitle,
                    industrieId: formData.industry,
                    companySize: formData.companySize,
                    id: account?.company?.id
                }))
            }
        } else {
            await dispatch(fetchUpdateCompanyByIdSupport({
                id: id,
                body: {
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    name: formData.companyName,
                    jobTitle: formData.jobTitle,
                    industrieId: formData.industry,
                    companySize: formData.companySize,
                }
            }))

        }

        setIsLoading(false)
    };


    const handleDeactivate = async (e) => {
        await dispatch(deactivateUser())

        setConfirm(false)
    };

    const deactivateHandler = async () => {
        if (!id) {
            setConfirm(true)
        } else {
            await dispatch(fetchDeactivateCompanyByIdSupport({
                id: accInfo?.owner?.id,
                isDeactivate: !accInfo?.owner?.isDeactivated
            }))
            req()
        }
    }


    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Account Page']}/>

            <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1240px] w-full m-auto flex-1">
                <div className="flex flex-col justify-center bg-white px-4">
                    {
                        !isLoading ?
                            <section
                                className="flex flex-col justify-between items-center px-8 lg:px-16 py-9 lg:py-[60px] bg-white rounded-md shadow-[0_5px_25px_-10px_rgba(0,0,0,0.15)] lg:mt-[24px]">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2 gap-x-[60px]">
                                        <div>
                                            <InputField
                                                label="Work Email"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="email" name="email" id="email"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.email} onChange={handleChange} error={errors.email}
                                            />
                                            <InputField
                                                label="First Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="firstName" id="firstName" placeholder="First name"
                                                value={formData.firstName} onChange={handleChange}
                                                error={errors.firstName}
                                            />
                                            <InputField
                                                label="Last Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="lastName" id="lastName" placeholder="Last name"
                                                value={formData.lastName} onChange={handleChange}
                                                error={errors.lastName}
                                            />
                                            <InputField
                                                label="Company Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="companyName" id="companyName" placeholder="Company name"
                                                value={formData.companyName} onChange={handleChange}
                                                error={errors.companyName}
                                                disabled={!account?.company && !id}
                                            />
                                            <SelectField
                                                label="Company Size"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="companySize"
                                                id="companySize"
                                                value={formData.companySize}
                                                onChange={handleChange}
                                                options={companySizeOptions}
                                                placeholder="Select company size"
                                                disabled={!account?.company && !id}
                                            />
                                            {errors.companySize &&
                                                <span className="text-red-500 mb-[18px]">{errors.companySize}</span>}
                                            <div className="grid lg:grid-cols-2 gap-x-5">
                                                <div>
                                                    <InputField
                                                        label="Job Title"
                                                        inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                        name="jobTitle" id="jobTitle" placeholder="Job title"
                                                        value={formData.jobTitle} onChange={handleChange}
                                                        disabled={!account?.company && !id}
                                                    />
                                                    {errors.jobTitle &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.jobTitle}</span>}
                                                </div>
                                                <div>
                                                    <SelectBox
                                                        options={industryOptions}
                                                        label="Industry"
                                                        onSelect={val => {
                                                            setFormData(prevState => ({...prevState, 'industry': val}))
                                                        }}
                                                        className={'!mt-[10px] mb-[18px]'}
                                                        value={formData?.industry} svg={2}
                                                        placeholder={formData?.industry}
                                                        disabled={!account?.company && !id}
                                                        labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}

                                                    />
                                                    {errors.industry &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.industry}</span>}
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            {/*<div className="flex flex-col gap-[8px] flex-1 mb-6 ">*/}
                                            {/*    <SelectBox*/}
                                            {/*        options={allPlans?.map(plan => ({*/}
                                            {/*            label: plan.title,*/}
                                            {/*            value: plan.id*/}
                                            {/*        })) || []}*/}
                                            {/*        label="Pricing Plan"*/}
                                            {/*        onSelect={val => {*/}
                                            {/*            setNewPlan(allPlans?.find(plan => plan.id === val))*/}
                                            {/*        }}*/}
                                            {/*        className={'!mt-[10px] '}*/}
                                            {/*        value={newPlan?.id || planInfo?.pricingPlanId} svg={2}*/}
                                            {/*        labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            {planInfo && <>
                                                <div className="flex flex-col gap-[8px] flex-1 mb-6 ">
                                                    <label
                                                        className="text-[18px] font-bold text-zinc-800 leading-[18px]">Features</label>
                                                    <div className="min-h-[55px]"
                                                         dangerouslySetInnerHTML={{__html: planInfo?.features}}></div>
                                                </div>
                                                {planInfo.price &&
                                                    <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                                        <label
                                                            className="text-[18px] font-bold text-zinc-800 leading-[18px]">Price</label>
                                                        <div>
                                                            ${planInfo?.price} / Month
                                                        </div>
                                                    </div>}

                                                {planInfo?.period && <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                                    <label
                                                        className="text-[18px] font-bold text-zinc-800 leading-[18px]">Period</label>
                                                    <div className="capitalize">
                                                        {planInfo?.period}
                                                    </div>
                                                </div>}
                                                {planInfo?.endDate && planInfo?.status !== 'active' &&
                                                    <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                                        <label
                                                            className="text-[18px] font-bold text-zinc-800 leading-[18px]">End
                                                            Date</label>
                                                        <div className="capitalize">
                                                            {format(planInfo?.endDate, "MM/dd/yyyy")}
                                                        </div>
                                                    </div>}
                                                {planInfo?.endDate && planInfo?.status !== 'active' &&
                                                    <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                                        <label
                                                            className="text-[18px] font-bold text-zinc-800 leading-[18px]">Next Payment</label>
                                                        <div className="capitalize">
                                                            {format(planInfo?.endDate, "MM/dd/yyyy")}
                                                        </div>
                                                    </div>}
                                                {planInfo?.status &&
                                                    <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                                        <label
                                                            className="text-[18px] font-bold text-zinc-800 leading-[18px]">Status</label>

                                                        <div
                                                            className={`uppercase font-bold ${planInfo?.status === "active" ? "text-green-500" : planInfo?.status === "pending" ? "text-yellow-500" : "text-red-600"}`}>
                                                            {planInfo?.status}
                                                        </div>
                                                    </div>}

                                                {!planInfo.isDemo && planInfo?.session?.url && planInfo?.status === "pending" && (
                                                    <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6">
                                                        <label
                                                            className="text-[18px] font-bold text-zinc-800 leading-[18px]">Payment</label>
                                                        <div>
                                                            Please pay <span
                                                            className="font-semibold">${planInfo?.price}</span>
                                                            <PrimaryButton
                                                                onClick={() => window.location.href = planInfo?.session?.url}
                                                                className="btn btn-secondary !h-fit p-1 !text-[14px] px-[20px]"
                                                            >
                                                                Pay
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </>}
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-center items-center max-md:flex-col gap-[22px] mt-[24px]">
                                        <button
                                            className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
                                            Save
                                        </button>
                                        <button type={"button"} onClick={() => deactivateHandler()}
                                                className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md ${accInfo?.owner?.isDeactivated ? "bg-[#37B34A]" : "bg-[#D70100]"} max-md:px-5 hover:opacity-70`}>
                                            {accInfo?.owner?.isDeactivated ? "Activate" : "Deactivate"}
                                        </button>
                                    </div>
                                </form>
                                {confirm && <ModalTemplate open={confirm} onClick={() => setConfirm(false)}
                                                           modalSize={'modal__dialog--600'}>
                                    <div className="modal__body gap-[32px]">
                                        <h1 className='text-[56px] font-bold uppercase'>Warning!</h1>
                                        <div className="shrink-0 h-1 bg-green-500 w-[266px]"/>
                                        <p>By deactivating your account, you will lose access to your profile, data, and
                                            all associated services. This action is permanent and cannot be undone.</p>
                                        <p> If you're sure, please confirm below.</p>
                                        <button onClick={() => handleDeactivate()}
                                                className="flex text-[18px] font-bold justify-center items-center py-[13px] px-[20px] bg-green-500 text-white min-w-[122px] rounded-md max-md:flex-1 hover:opacity-70">
                                            Confirm
                                        </button>
                                    </div>
                                </ModalTemplate>}
                            </section>
                            :
                            <div className='text-4xl md:text-5xl font-bold text-sky-950 text-center'>Load
                                information</div>
                    }
                </div>
            </main>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SUP1_1;
