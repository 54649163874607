import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom"
import {ReactComponent as CalendarIcon} from "../../src/assets/img/calendar-green-icon.svg"
import {ReactComponent as ClockIcon} from "../../src/assets/img/clock-blue-icon.svg"
import postFrontImage from "../../src/assets/img/home1_12-post-image.png"
import firstScreenBg from "../../src/assets/img/home1_14-bg.png"
import Breadcrumbs from "../components/base/Breadcrumbs"
import {Footer} from "../components/parts/Footer"
import {Header} from "../components/parts/Header"
import {getSupportSolutionPageByIdFetch} from "../redux/thunks/solutionThunks"
import PageLoader from "../components/PageLoader"
import getImageUrl from "../assets/helpers/getImageUrl";
import {format} from "date-fns"
import SidebarAnhors from "../components/parts/SidebarAnhors"
import defaultImage from "../assets/img/No_Image_Available.jpg";
import ReactPlayer from "react-player";

const formatTags = (tags, length) => {
    return tags?.length ? tags.slice(0, length || tags.length).map(tag => tag?.name).join(", ") : ""
}

const PostHeading = ({article}) => {
    const isImageUrl = (url) => {
        return /\.(jpeg|jpg|gif|png|webp|bmp|svg|ico)$/i.test(url);
    }
        const calculateReadTime = (articleBody, wordsPerMinute = 200) => {
        const wordCount = articleBody.split(/\s+/).length;

        const readingTimeMinutes = wordCount / wordsPerMinute;

        const readingTime = Math.ceil(readingTimeMinutes);

        return readingTime;
    }
    return (
        <div className="mt-6 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div
                    className="flex flex-col w-[45%] max-md:ml-0 order-last md:order-first max-md:w-full mt-[44px] md:mt-[0px]">
                    {isImageUrl(getImageUrl(article.mainImage)) ?
                        <img loading="lazy" src={getImageUrl(article.mainImage)} alt="Article Image"
                             className="object-contain grow w-full aspect-[0.87]  max-md:max-w-full"
                             onError={(e) => (e.target.src = defaultImage)}

                        />
                        :
                        <div className="video-player">
                            <ReactPlayer
                                light={<video src={getImageUrl(article.mainImage)}></video>}
                                playing={true}
                                controls={true}
                                width="100%"
                                height="100%"
                                playIcon={
                                    <button
                                        className="bg-white rounded-full absolute w-[100px] h-[100px] max-md:w-[50px] max-md:h-[50px] flex items-center justify-center">
                                            <span className="ml-3 max-md:ml-1">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="w-[47px] h-[54px] max-md:w-[23px] max-md:h-[27px]"
                                                     viewBox="0 0 47 54" fill="none">
                                                    <path d="M47 27L0.499997 53.8468L0.5 0.15321L47 27Z"
                                                          fill="#A2A1A1"/>
                                                </svg>
                                            </span>
                                    </button>
                                }
                                url={getImageUrl(article.mainImage)}
                            />
                        </div>
                    }
                </div>
                <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full ">
                    <div
                        className="flex flex-col items-start self-stretch my-auto text-xl text-white max-md:max-w-full">
                        <h1 className="text-[32px] md:text-4xl font-bold text-white leading-[50px]">{article.name}</h1>
                        <div className="shrink-0 mt-6 h-1 bg-green-500 w-[78px]"/>
                        <p className="self-stretch mt-6 leading-9 max-md:max-w-full">
                            {article.postSummary}
                        </p>
                        <div
                            className="mt-[24px] md:mt-10 whitespace-nowrap leading-[150%] font-bold">{article.user.firstName + " " + article.user.lastName}</div>
                        <div className="leading-[150%]">{formatTags(article.tags)}</div>
                        <div
                            className="flex flex-wrap gap-5 justify-between mt-[24px] md:mt-10 font-light whitespace-nowrap leading-[170%]">
                            <div className="flex items-center gap-2.5">
                                    <span className="shrink-0 w-6 aspect-square">
                                        <ClockIcon/>
                                    </span>
                                <div className="grow my-auto">{calculateReadTime(article.postBody)} min read</div>
                            </div>
                            <div className="flex items-center gap-2.5">
                                    <span className="shrink-0 w-6 aspect-square">
                                        <CalendarIcon/>
                                    </span>
                                <div className="grow my-auto">{format(article.createdAt, "MMMM dd yyyy")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SBM03_1 = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {article, isLoading} = useSelector(state => state.solution)
    const breadcrumbs = [
        {name: 'Subscriber Dashboard', to: "/SBM01"},
        {name: 'Support Center', to: "/SBM03"},
        {name: 'Article', to: "/", isCurrent: true}
    ]

    useEffect(() => {
        if (!id) return
        dispatch(getSupportSolutionPageByIdFetch(id))
    }, [id])

    return (<>

            <div className="box-border flex relative flex-col flex-auto">
                <Header/>
                <div
                    className={`
                    box-border flex absolute left-0 z-0 self-stretch w-full bg-center bg-no-repeat bg-cover
                    h-full
                `}
                    style={{
                        zIndex: "-1",
                        backgroundImage: `url(${firstScreenBg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                />
                <div className="flex justify-center items-center px-16 pt-[24px] pb-[80px] md:py-16 max-md:px-[22px]">
                    <section className="flex flex-col max-w-full w-[1240px]">
                        <Breadcrumbs links={breadcrumbs}/>
                        {
                            isLoading ?
                                <PageLoader color="light"/>
                                : article ?
                                    <PostHeading
                                        imageSrc={postFrontImage}
                                        article={article}
                                    />
                                    :
                                    <div className="flex items-center justify-center text-center flex-auto my-7">
                                        <h1 className="text-white text-7xl font-bold">Not Found</h1>
                                    </div>
                        }
                    </section>

                </div>
            </div>
            {
                !isLoading && article ?
                    <div
                        className="flex flex-col items-center py-[44px] md:px-16 pt-[44px] pb-[80px] md:py-[96px] max-md:px-5">
                        <section className="justify-between max-w-full w-[1240px]">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full article"
                                     dangerouslySetInnerHTML={{__html: article.postBody}}></div>
                                <SidebarAnhors style="dark" htmlContent={article.postBody}/>
                            </div>
                        </section>
                    </div>
                    :
                    null
            }
            <Footer/>

        </>
    )
}

export default SBM03_1
