import * as React from "react";
import linkArrow from "../assets/img/link-arrow.svg";
import sliderImg from "../assets/img/slider-img.png";
import Breadcrumbs from "../components/base/Breadcrumbs";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getAuthorized, getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import getImageUrl from "../assets/helpers/getImageUrl";
import PageLoader from "../components/PageLoader";


function InfoSection1({ title, content, img }) {
  return (
    <div className="flex flex-col items-center text-center gap-[16px]">
      <img src={img} alt="img" className="aspect-square  w-[100px] lg:w-[124px] object-contain" />
      <h3 className="text-4xl lg:text-[2.5rem] text-white font-bold">{title}</h3>
      <p className="text-lg text-white font-normal ">{content}</p>
    </div>
  );
}

const HOM1_8 = () => {
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  const breadcrumbItems = [
    { name: 'Home', to: "/", isCurrent: false },
    { name: 'Careers', to: "/HOM1_8", isCurrent: true },
  ];
  const sectionsData = [
    { title: "Authenticity", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-1.png') },
    { title: "Outcome Obsessed", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-2.png') },
    { title: "Team First", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-3.png') },
    { title: "Show Grit", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-4.png') },
    { title: "Team First", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-5.png') },
    { title: "Show Grit", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.", img: require('../assets/img/HOM1_8-list-6.png') },
  ];
  return (
    <div className="mx-5 lg:mx-[40px]">
      <div className="max-w-[1240px] mx-auto mb-[24px] lg:mb-[32px]">
        <Breadcrumbs
          links={breadcrumbItems}
        />
      </div>
      {
        currentPage ?
          <>
            <App />
            <h2 className="mt-[166px] text-[40px] leading-[48px] lg:text-[50px] lg:leading-[52px] text-white font-bold text-center">
              {getValue('benefits_title')}
            </h2>
            <section className="max-w-[1240px] mx-auto grid grid-cols-2 gap-10 mt-10 w-full max-md:grid-cols-1">
              {getValue('benefits_items')?.data?.map((section, index) => (
                <InfoSection1
                  key={index}
                  title={section.title}
                  img={getImageUrl(section.image)}
                  content={section.description}
                />
              ))}
            </section>
            <h2 className="mt-[80px] lg:mt-[96px] text-[2.5rem] text-white font-bold text-center">
              {getValue('team_block_title')}
            </h2>
            <CareersPage />
          </>
          :
          <PageLoader color="light" />
      }
    </div>
  )
};

const Slide = ({ src, alt, heading, text }) => (
  <div className="flex flex-col justify-center items-center gap-[44px] lg:gap-[50px] justify-between lg:flex-row max-w-[1240px] mx-[10px] lg:mx-[80px]">
    <div>
      <h2 className="text-[36px] text-center lg:text-6xl lg:text-start font-bold text-white">{heading}</h2>
      <div className="w-[78px] h-[4px] bg-[#37B34A] my-[24px]" />
      <p className="text-xl font-normal text-white line-clamp-5">{text}</p>
    </div>
    <img src={src} alt={alt} className="max-w-full aspect-square h-auto max-h-[346px]" />
  </div>
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false
      },
    },
  ],
};

const ImageSlider = ({ images }) => {
  return (
    <Slider {...settings}>
      {images?.data?.map((image, index) => (
        <Slide key={index} src={getImageUrl(image.image)} heading={image.title} text={image.description} />
      ))}
    </Slider>
  );
};

const App = () => {
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);

  return (
    <div className="max-w-[1544px] mx-auto lg:px-[72px] mb-[70px]">
      <ImageSlider images={getValue('top_slider_items')} />
    </div>
  );
};


const SolutionCard = ({ title, tags, detail, buttonText, imageAlt, imageSrc, isAuth, title_description }) => {
  return (
    <section className="flex flex-col grow px-5 py-7 mx-auto max-w-full font-bold rounded-md bg-slate-100 text-zinc-800 lg:px-10">
      <h2 className="text-2xl lg:text-4xl whitespace-nowrap ">{title}</h2>
      <div className="flex gap-3 mt-5 text-lg text-green-500 whitespace-nowrap md:mr-2.5">
        {tags.map((tag, index) => (
          <div key={index} className="flex items-center justify-center truncate px-5 h-8 bg-white rounded-[360px]">
            {tag}
          </div>
        ))}
      </div>
      <p className="mt-9 text-2xl md:mr-1">{title_description}</p>
      <p className="font-normal mt-3 text-lg leading-8 md:mr-1">{detail}</p>
      {
        isAuth ?
          <Link to={"/HOM1_8_1"} className='flex justify-center items-center lg:text-[1.375rem] font-bold text-[19px] h-16 lg:h-[4.625rem] whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67] gap-[6px] w-full lg:w-[236px] mt-[36px]'>
            Learn more
            <img src={linkArrow} alt="arrow" />
          </Link>
          :
          <Link to={"/HOM1_5"} className='flex justify-center items-center lg:text-[1.375rem] font-bold text-[19px] h-16 lg:h-[4.625rem] whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67] gap-[6px] w-full lg:w-[236px] mt-[36px]'>
            {buttonText}
            <img src={linkArrow} alt="arrow" />
          </Link>
      }
    </section>
  );
};

const CareersPage = () => {
  const isAuth = useSelector(getAuthorized);
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  const solutions = [
    {
      title: "Solutions Engineer",
      tags: ["Sales", "Engineering"],
      detail: "The Solutions Engineer will play a key role in serving data teams of all kinds. The ideal candidate needs to have experience selling complex SaaS into IT teams, especially earning the trust of engineers and helping them succeed.",
      buttonText: "Book A Demo",
      imageAlt: "Book demo icon",
      imageSrc: "imageSrc1.png"
    },
    {
      title: "Solutions Engineer",
      tags: ["Sales", "Engineering"],
      detail: "The Solutions Engineer will play a key role in serving data teams of all kinds. The ideal candidate needs to have experience selling complex SaaS into IT teams, especially earning the trust of engineers and helping them succeed.",
      buttonText: "Book A Demo",
      imageAlt: "Book demo icon",
      imageSrc: "imageSrc2.png"
    },
    {
      title: "Solutions Engineer",
      tags: ["Sales", "Engineering"],
      detail: "The Solutions Engineer will play a key role in serving data teams of all kinds. The ideal candidate needs to have experience selling complex SaaS into IT teams, especially earning the trust of engineers and helping them succeed.",
      buttonText: "Book A Demo",
      imageAlt: "Book demo icon",
      imageSrc: "imageSrc3.png"
    }
  ];

  return (
    <main className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-[30px] lg:mt-[56px]">
      {getValue('items_team_block')?.data?.map((solution, index) => (
        <SolutionCard
          key={index}
          isAuth={isAuth}
          buttonText={solution.button_title}
          detail={solution.description}
          title={solution.title}
          title_description={solution.title_description}
          tags={JSON.parse(solution.tags)}
        />
      ))}
    </main>
  );
};

export default HOM1_8;
