const TextareaFieldHorisontal = ({ label, labelClassName, name, id, errorClassName= "", value, onChange, error, inputBg = "bg-[#F0F3F8]", placeholder="", rows , afterLabel = null }) => (
    <div className="flex flex-col gap-[5px]">
      <div className="flex items-start max-md:flex-col gap-[12px] md:gap-[16px] flex-1">
        {label && <label htmlFor={id} className={labelClassName ? labelClassName : "min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"}>{label}</label> }
        { afterLabel }
        <textarea
          id={id}
          rows={rows}
          name={name}
          className={`flex-auto rounded-md text-[16px] py-[16px] px-[16px] focus:outline-none focus:ring-2 focus:ring-green-500 ${error ? 'border-red-500 bg-red-100' : inputBg} w-full`}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        ></textarea>

      </div>
      {error && <p className={`text-red-500 text-sm mt-1 ${errorClassName}`}>{error}</p>}
    </div>
);

export default TextareaFieldHorisontal;
