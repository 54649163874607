import React, {useEffect, useState} from 'react'
import CallModal from "../modals/CallModal";
import SmsModal from "../modals/SmsModal";
import EmailModal from "../modals/EmailModal";
import AddTagModal from "../modals/AddTagModal";
import {toast} from "react-toastify";
import {useOutletContext, useParams} from "react-router-dom";
import api from "../../api";


const ModalGrop = ({
                       theme = '1',
                       callModal = true,
                       tagModal = true,
                       cmcModal = true,
                       emailModal = true,
                       selectedApplicants = null,
                       group = false
                   }) => {
    const [callPopup, setCallPopup] = useState(false)
    const [smsPopup, setSmsPopup] = useState(false)
    const [emailPopup, setEmailPopup] = useState(false)
    const [addTagPopup, setAddTagPopup] = useState(false)
    const {jobId, applicationId} = useParams();
    const [applicationData, setApplicationData] = useState(selectedApplicants);

    const handleButtonClick = (setter, requiresApplicant = true) => {
        if (requiresApplicant && !selectedApplicants && group) {
            toast.error("Please select an applicant first.");
            return;
        }
        setter(prev => !prev);
    };

    const getJobApplicationById = async () => {
        if (!jobId || !applicationId) return;
        try {

            const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
            setApplicationData([res?.data]);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!group && !selectedApplicants) {
            getJobApplicationById()
        }
    }, [applicationId, jobId]);

    useEffect(() => {
        setApplicationData(selectedApplicants)
    }, [selectedApplicants]);

    return (
        <>
            {theme === '2' && <>
                {callModal && <button disabled={applicationData?.length > 1} type="button"
                                      className={`grow justify-center ${applicationData?.length > 1 && "disabled"} text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid`}
                                      onClick={() => handleButtonClick(setCallPopup)}>
                    Call
                </button>
                }
                {cmcModal && <button type="button"
                                     className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid"
                                     onClick={() => handleButtonClick(setSmsPopup)}>
                    SMS
                </button>}
                {emailModal && <button type="button"
                                       className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid"
                                       onClick={() => handleButtonClick(setEmailPopup)}>
                    Email
                </button>
                }
                {tagModal && <button type="button" disabled={!applicationData}
                                     className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid"
                                     onClick={() => handleButtonClick(setAddTagPopup)}>
                    Add Tag
                </button>
                }
            </>
            }
            {theme === '1' && <>
                {callModal && <ActionButton text="Call" onClick={() => handleButtonClick(setCallPopup)}/>}
                {cmcModal && <ActionButton text="SMS" onClick={() => handleButtonClick(setSmsPopup)}/>}
                {emailModal && <ActionButton text="Email" onClick={() => handleButtonClick(setEmailPopup)}/>}
                {tagModal && <ActionButton text="Add Tag" onClick={() => handleButtonClick(setAddTagPopup)}/>}
            </>}
            {callPopup && <CallModal
                selectedApplicants={applicationData}
                open={callPopup}
                tableHeding={true}
                handleClose={() => handleButtonClick(setCallPopup)}
            />}
            {smsPopup && <SmsModal
                open={smsPopup}
                tableHeding={true}
                selectedApplicants={applicationData}
                handleClose={() => handleButtonClick(setSmsPopup)}
            />}
            {emailPopup && <EmailModal
                open={emailPopup}
                tableHeding={true}
                selectedApplicants={applicationData}
                handleClose={() => handleButtonClick(setEmailPopup)}
            />}
            {addTagPopup &&
                <AddTagModal
                    open={addTagPopup}
                    selectedApplicants={applicationData}
                    modalSize='modal__dialog--1000'
                    handleClose={() => handleButtonClick(setAddTagPopup)}
                    type='candidate'
                />}
        </>
    )
}
const ActionButton = ({text, onClick, disabled = false}) => (
    <button disabled={disabled} type="button"
            className="justify-center text-green-500 text-lg h-[50px] w-[110px] font-semibold rounded-md border border-green-500 border-solid hover:opacity-70"
            onClick={onClick}>
        {text}
    </button>
);
export default ModalGrop
