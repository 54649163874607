import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getAuthorized, getUser } from '../redux/slices/applicationSlice';
import { useEffect } from 'react';
import api from '../api';
import { toast } from 'react-toastify';

const protectedPages = {
    "/jobs-setup": ['admin', 'member']
    // "/jobs-setup": ['subscriber',]
}

const ConfirmEmailLayout = () => {
    const user = useSelector(getUser);
    const authorized = useSelector(getAuthorized);
    const { pathname } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const { token } = useParams();
    // if (authorized === false) {
    //     return <Navigate to="/login" />
    // }
    // if (protectedPages[pathname] && user?.role && !protectedPages[pathname].includes(user.companies[0]?.role)) {
    //     return <Navigate to="/" />
    // }
    console.log(userId)
    const navigate = useNavigate();
    useEffect(() => {
        api.companyTeam.confirmEmail({userId, token}).then(response => {
            console.log(response);
            toast(response.message, {
                type: response.success ? 'success' : 'error'
            })
            navigate('/login')
        })
    }, [userId, token])
    return null
};

export default ConfirmEmailLayout
