import {Fragment, useEffect, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {EvaluationInterview} from '../../../components/parts/EvaluationsInterview';
import {NextStepSectionSBM01} from '../base/NextStepSectionSBM01';
import api from "../../../api";
import PrimaryButton from "../../../components/base/PrimaryButton";
import {createJobRatingsFetch, updateJobRatingsFetch} from "../../../redux/thunks/jobRatingsThunks";
import {toast} from "react-toastify";
import {getApplicationByIdFetch, getJobByIdFetch} from "../../../redux/thunks/jobsThunks";
import {useDispatch, useSelector} from "react-redux";
import {QuestionTypes} from "../../AP_01_Form/parts/questionTypes";
import {getSubscriberRole} from "../../../redux/slices/applicationSlice";

const questionsData = [
    {
        "id": 1,
        "question": "Cannot Work in the US",
        "answerType": "yes-no",
    },
    {
        "id": 2,
        "question": "Pay",
        "answerType": "text",
    },
    {
        "id": 3,
        "question": "Location",
        "answerType": "text",
    }
]


const Scores = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})
    const subscriberRole = useSelector(getSubscriberRole);


    const [aiFields, setAiFields] = useState(null)
    // const [scores, setScores] = useState({})

    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    useEffect(() => {
        setActiveStep(7)
        // api.hiringAlgorithm.getScores(applicationId).then(response => {
        //     setScores(response.data)
        // })
        api.hiringAlgorithm.getFields(applicationId).then(response => {
            setAiFields(response.data)
        })
    }, [])

    const handlePrev = () => {
        navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_5/${jobId}/${applicationId}`)
    }

    const aiConfig = aiFields && typeof aiFields === "object" && Object.keys(aiFields).reduce((config, key) => {
        config[key] = {
            key: key,
            name: key?.replaceAll("_", " ")?.replace(/\b\w/g, char => char?.toUpperCase()),
            score: aiFields[key],
        };
        return config;
    }, {});
    // const scoresConfig = Object.keys(scores).reduce((config, key) => {
    //     config[key] = {
    //         key: key,
    //         name: key?.replaceAll("_", " ")?.replace(/\b\w/g, char => char?.toUpperCase()),
    //         score: scores[key],
    //     };
    //     return config;
    // }, {});


    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name.toLowerCase().replaceAll(" ", "_").replaceAll("?", "")]: {
                title: name,
                value: value
            }
        }))
    }

    useEffect(() => {
        if (application?.assessments?.["qa-scores"]) {
            setFormData(application?.assessments?.["qa-scores"])
        }
    }, [application])


    const handleSubmit = async () => {
        setIsLoading(true)

        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    ["qa-scores"]: formData
                }
            })
            if (resp.success) {
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }

    if (!aiConfig) {
        return null
    }


    const handleSaveFile = async () => {
        setIsLoading(true);

        try {
            const url = `${process.env.REACT_APP_API_URL}/${applicationId}`;
            const response = await fetch(url, {method: 'GET'});
            if (!response.ok) {
                throw new Error('Failed to fetch the file');
            }

            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Hiring data.xlsx`;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } catch (err) {
            console.error(err);
            toast.error("Something went wrong while downloading the file");
        }

        setIsLoading(false);
    };


    return (
        <div className='flex-auto'>
            {/*<div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>*/}
            {/*    <div className="border-b border-neutral-200 pb-[24px] md:pb-9"><span*/}
            {/*        className="text-[28px] md:text-3xl font-bold">Scores</span></div>*/}
            {/*    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-[100px] gap-y-6 max-w-[900px]">*/}
            {/*        {Object.keys(scoresConfig)?.length && Object.keys(scoresConfig).map(key =>*/}
            {/*            <div className="flex w-full items-center justify-between">*/}
            {/*                <div className="font-bold text-[18px]">*/}
            {/*                    {scoresConfig[key]?.name}*/}
            {/*                </div>*/}
            {/*                <div*/}
            {/*                    className="flex gap-2 justify-center px-5 py-4 w-fit min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5 min-h-[58px]">*/}
            {/*                    {scoresConfig[key]?.score}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full  pb-20 lg:pt-14 lg:pb-24 mx-auto'>
                <div className="border-b border-neutral-200 pb-[24px] md:pb-9 flex justify-between items-center w-full">
                    <span
                        className="text-[28px] md:text-3xl font-bold">Data for AI Recommendations
                    </span>
                    <PrimaryButton
                        isLoading={isLoading}
                        type='button'
                        onClick={handleSaveFile}
                        className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                    >
                        Download xlsx
                    </PrimaryButton>
                    <Fragment></Fragment>
                </div>
                <div className="grid grid-cols-2 max-md:grid-cols-1 gap-x-[100px] gap-y-6 max-w-[900px]">
                    {!!Object.keys(aiConfig)?.length && Object.keys(aiConfig)?.filter((key) => !/^_/.test(key))?.map(key => {
                            return (<div className="flex w-full items-center justify-between">
                                <div className="font-bold text-[18px]">
                                    {aiConfig[key].name}
                                </div>
                                <div
                                    className="flex gap-2 justify-center px-5 py-4 w-fit min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5 min-h-[58px]">
                                    {aiConfig[key].score}
                                </div>
                            </div>)
                        }
                    )
                    }
                </div>
            </div>
            {/*<div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full  pb-20 lg:pt-14 lg:pb-24 mx-auto'>*/}
            {/*    <div className="border-b border-neutral-200 pb-[24px] md:pb-9"><span*/}
            {/*        className="text-[28px] md:text-3xl font-bold">QA Categories Scores</span></div>*/}
            {/*    <div className="max-w-[900px] flex flex-col gap-6">*/}
            {/*        {questionsData.map((item, index) => {*/}
            {/*            const Question = QuestionTypes[item.answerType];*/}
            {/*            if (!Question) return null*/}
            {/*            return (*/}
            {/*                <Question*/}
            {/*                    key={item.id}*/}
            {/*                    error={null}*/}
            {/*                    config={item}*/}
            {/*                    number={index + 1}*/}
            {/*                    value={formData?.[item?.question.toLowerCase().replaceAll(" ", "_").replaceAll("?", "")]?.value}*/}
            {/*                    className="mt-[0px]"*/}
            {/*                    onChange={(value) => handleChange(item?.question, value)}*/}
            {/*                    labelClassName="!text-[16px] !leading-[20px]"*/}
            {/*                    disabled={subscriberRole === "interviewer"}*/}
            {/*                />)*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*    { subscriberRole !== "interviewer" && <div className="flex justify-end">*/}
            {/*        <PrimaryButton*/}
            {/*            isLoading={isLoading}*/}
            {/*            className="justify-center max-md:w-full lg:!h-[52px] lg:!text-lg px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"*/}
            {/*            onClick={handleSubmit}*/}
            {/*        >*/}
            {/*            Save*/}
            {/*        </PrimaryButton>*/}
            {/*    </div>}*/}
            {/*</div>*/}
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}

export default Scores
