import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";
import Breadcrumbs from "../components/base/Breadcrumbs";
import PrimaryButton from "../components/base/PrimaryButton";
import PrimaryLink from "../components/base/PrimaryLink";
import { getCurrentPage, setToken } from "../redux/slices/applicationSlice";
import { getUserFetch } from "../redux/thunks/applicationThunks";

import { ReactComponent as SpinnerIcon } from "../assets/img/spinner.svg";
import InputField from "../components/base/InputField";


const SignInButton = ({ src, alt, children, onClick = () => { } }) => (
	<button
		className="flex justify-center items-center px-16 lg:h-[64px] h-[60px] mt-8 max-w-full lg:text-[19px] text-xl whitespace-nowrap bg-white rounded-md text-zinc-700 w-[562px] max-md:px-5 max-md:mr-2.5 hover:opacity-70"
		onClick={onClick}
	>
		<div className="flex gap-3">
			<img loading="lazy" src={src} alt={alt} className="w-8 aspect-square" />
			<div className="grow my-auto">{children}</div>
		</div>
	</button>
);

function HOM1_15() {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const recaptchaRef = useRef()
	const [isRecaptchaError, setIsRecaptchaError] = useState(false)
	const [isLoginFailed, setIsLoginFailed] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const [loginErrorMessage, setLoginErrorMessage] = useState(false)

	const [payload, setPayload] = React.useState({
		email: "",
		password: "",
	});


	const hanldeCapchaChange = (value) => {
		setIsRecaptchaError(!value)
	}

	const login = async () => {
		const recaptchaValue = recaptchaRef.current.getValue();
		if (!recaptchaValue) {
			setIsRecaptchaError(true)
			return
		}
		setIsLoading(true)

		const response = await api.auth.login({ ...payload, capchaKey: recaptchaValue });
		if (response?.success && response?.data && response.data?.token) {
			dispatch(setToken(response.data.token));
			const res = await dispatch(getUserFetch()).unwrap();

			if (res && res?.role === "support") {
				localStorage.setItem('authToken', response.data?.token);
				toast.success("Login success")

				navigate('/SUP01');
			}
		} else {
			setIsLoginFailed(true)
			setLoginErrorMessage(response?.message || "Something went wrong")
		}
		setIsLoading(false)

	}

	return (<>
		<div className="max-w-[1280px] px-5 w-full mx-auto pt-[10px] lg:pt-[0px]">
			<Breadcrumbs links={[
				{ name: 'Home', to: "/" },
				{ name: 'Support Login', to: "/support-login", isCurrent: true }
			]} />
		</div>
		{
			currentPage ?
				<div className="flex relative flex-col text-center text-balance items-center mb-[100px] lg:mb-9 w-full max-w-[677px] px-4 mx-auto">
					<h2 className="mt-[45px] lg:mt-8 lg:text-[70px] text-white lg:leading-[79.8px] text-wrap font-bold max-md:max-w-full leading-[48px] text-[40px]">
						{getValue('content_title')}
					</h2>
					<div className="self-center mt-6 h-1 bg-[#37B34A] w-[78px]" />
					<div className="flex flex-col justify-center items-center max-w-[560px] w-full mx-auto">
						<SignInButton src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e5f03387e510f5b32af280b63ab0ba9deaece36abad31da95dda6b1c9f54e70?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&" alt="Sign in with Google icon">Sign in with Google</SignInButton>
						<div className="self-center mt-5 text-xl font-light text-white">or</div>
						<InputField
							parentClassName={"w-full"}
							className={"items-start h-[52px] lg:h-16 pr-16 pl-6 text-[19px] lg:text-xl whitespace-nowrap !bg-white rounded-md text-zinc-500 w-full max-md:px-5 max-md:mr-2.5"}
							placeholder="Email or Username"
							value={payload.email}
							onInput={(e) => {
								setPayload((prev) => {
									return {
										...prev,
										email: e.target.value
									}
								})
							}}
						/>
						<InputField
							parentClassName={"w-full"}
							className={"items-start h-[52px] lg:h-16 pr-16 pl-6 text-[19px] lg:text-xl whitespace-nowrap !bg-white rounded-md text-zinc-500 w-full max-md:px-5 max-md:mr-2.5"}
							placeholder="Password"
							value={payload.password}
							type="password"
							onInput={(e) => {
								setPayload((prev) => {
									return {
										...prev,
										password: e.target.value
									}
								})
							}}
						/>
						{/* <div className="flex gap-5 justify-between px-3 py-2 mt-6  max-w-full font-medium rounded-sm border border-solid shadow-sm bg-neutral-50 border-zinc-300 w-[302px] max-md:mr-2.5">
					<CheckboxWithIcon />
					<div className="flex flex-col justify-center text-xs text-center whitespace-nowrap text-neutral-400">
						<img loading="lazy" src={RecaptchaImg} alt="Recaptcha logo" className="self-center w-14 aspect-[1.22]" />
						<a href="#" className="mt-1 hover:opacity-70">Privacy - Terms</a>
					</div>
				</div> */}
						<div className="mt-6">
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_SITE_KEY}
								onChange={hanldeCapchaChange}
								onErrored={(mess) => console.log(mess)}
								hl="en"
							/>
							{isRecaptchaError && <p className="text-red-500 text-sm mt-1">Invalid capcha</p>}
						</div>
						<PrimaryButton
							className='w-full mt-6'
							onClick={(e) => {
								e.preventDefault();
								login();
							}}
							disabled={isLoading}
						>
							{
								!isLoading ?
									getValue('content_button_text')
									:
									<SpinnerIcon />
							}
						</PrimaryButton>
						{
							isLoginFailed && loginErrorMessage ?
								<p className="mt-11 md:text-xl text-lg text-balance text-[red] font-normal w-full text-center">
									{loginErrorMessage}
								</p>
								:
								null
						}
						{
							isLoginFailed ?
								<p className="mt-11 md:text-xl text-lg text-balance text-white font-normal w-full text-center">
									<PrimaryLink to={"/HOM-1_6_1"}>Forgot your password?</PrimaryLink>
								</p>
								:
								null
						}
					</div>
				</div>
				:
				<PageLoader color="light" />
		}
	</>);
}

export default HOM1_15;
