const base = 'api/communications';

export const communications = (instance) => ({
    async getAllComunications({applicationId, query = ""}) {
        const {data} = await instance.get(`${base}/${applicationId}?limit=100&${query}`);
        return data;
    },
    async sendMessageToApplicant({applicationId, body}) {
        const {data} = await instance.post(`${base}/${applicationId}/send-message`, body);
        return data;
    },
    async getCallToken(applicationId) {
        const {data} = await instance.post(`api/twilio/token/${applicationId}`);
        return data;
    },
    async getAllTemplates(companyId, type) {
        const { data } = await instance.get(`api/templates/${companyId}?limit=100${type ? "&type=" + type : ""}`);
        return data;
    },
    async createTemplate(companyId, body) {
        const {data} = await instance.post(`api/templates/${companyId}`, body);
        return data;
    },
    async updateTemplate(companyId, id, body) {
        const {data} = await instance.put(`api/templates/${companyId}/${id}`, body);
        return data;
    },
    async deleteTemplate(companyId, id) {
        const {data} = await instance.delete(`api/templates/${companyId}/${id}`);
        return data;
    },
});

