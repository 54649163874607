export const companySizeOptions = [
    {value: '1-10', label: '1-10 Employees'},
    {value: '11-30', label: '11-30 Employees'},
    {value: '31-50', label: '31-50 Employees'},
    {value: '51-75', label: '51-75 Employees'},
    {value: '76-100', label: '76-100 Employees'},
    {value: '101-150', label: '101-150 Employees'},
    {value: '151-200', label: '151-200 Employees'},
    {value: '200+', label: '200+ Employees'},
];
export const periodsOptions = [
    {value: "monthly", label: "Monthly Subscription"},
    {value: "quarterly", label: "Quarterly Subscription"},
    {value: "semi-annual", label: "Semi-Annual Subscription"},
    {value: "yearly", label: "Annually Subscription"},
]
