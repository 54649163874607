import React, { useEffect, useRef, useState } from 'react'
import moveCursorToEnd from '../../assets/helpers/moveCursorToEnd';

const OptionList = ({ el, idx, removeAction, editAction = false, change }) => {
    const [edit, setEdit] = useState(editAction);
    const [value, setValue] = useState(el.title || el?.title?.text || "")
    const editableOptionRef = useRef(null);

    useEffect(() => {
        if ( el?.title?.text) {
            setValue(el.title.text)
        }
        if ( el?.text) {
            setValue(el.text)
        }
    }, [el])

    useEffect(() => {
        if (edit) {
            console.log(editableOptionRef.current);
            editableOptionRef.current.focus()
            // moveCursorToEnd(editableOptionRef.current)
        }
    }, [edit])

    return (
        <li
            className="mb-8 pb-8 border-b border-b-neutral-200 flex items-center justify-between max-md:flex-col"
        >
            <div className="flex items-center  gap-3 max-md:justify-between max-md:w-full max-md:mb-[20px]  min-w-[350px] ">
                {/* <span
                    className="text-5"
                    tabIndex={'-1'}
                    ref={editableOptionRef}
                    suppressContentEditableWarning={true}
                    contentEditable={edit}
                >
                    {el.title}
                </span> */}
                <input
                    className="text-5 bg-transparent focus:outline-none min-w-[300px]"
                    tabIndex={'-1'}
                    ref={editableOptionRef}
                    value={value}
                    disabled={!edit}
                    onInput={(e) => {
                        setValue(e.target.value);
                    }}
                    onBlur={() => change && change(value)}
                    placeholder='New Category'
                />
                {!edit ? <a href="#" className="d-flex items-center justify-center hover:opacity-70" onClick={(e) => {
                    e.preventDefault();
                    setEdit(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path
                            d="M8.16797 19.848L13.3165 19.8305L24.5538 8.70048C24.9948 8.25948 25.2375 7.67381 25.2375 7.05081C25.2375 6.42781 24.9948 5.84215 24.5538 5.40115L22.7035 3.55081C21.8215 2.66881 20.2826 2.67348 19.4076 3.54731L8.16797 14.6796V19.848ZM21.0538 5.20048L22.9076 7.04731L21.0445 8.89298L19.1941 7.04381L21.0538 5.20048ZM10.5013 15.6526L17.5363 8.68415L19.3866 10.5345L12.3528 17.5006L10.5013 17.5065V15.6526Z"
                            fill="#BEBEBE" />
                        <path
                            d="M5.83333 24.5H22.1667C23.4535 24.5 24.5 23.4535 24.5 22.1667V12.054L22.1667 14.3873V22.1667H9.51767C9.48733 22.1667 9.45583 22.1783 9.4255 22.1783C9.387 22.1783 9.3485 22.1678 9.30883 22.1667H5.83333V5.83333H13.8215L16.1548 3.5H5.83333C4.5465 3.5 3.5 4.5465 3.5 5.83333V22.1667C3.5 23.4535 4.5465 24.5 5.83333 24.5Z"
                            fill="#BEBEBE" />
                    </svg>
                </a> : null}
            </div>

            {edit ?
                <button
                    className="w-[111px] flex justify-center items-center py-[14px] px-[27px] bg-[#37B34A] rounded-md max-md:px-5 text-[#FFFFFF] max-md:w-full hover:opacity-70"
                    onClick={(e) => {
                        e.preventDefault();
                        setEdit(null);
                        change && change(value);
                    }}
                >
                    Save
                </button> :
                <button
                    className="flex justify-center items-center py-[14px] px-[27px] bg-[#D70000] rounded-md max-md:px-5 text-[#FFFFFF] max-md:w-full hover:opacity-70"
                    onClick={removeAction}
                >
                    Remove
                </button>
            }
        </li>
    )
}

export default OptionList
