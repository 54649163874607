const base = 'api/job-application-ratings';

export const jobRatings = (instance) => ({
    async getAllJobRatings({ applicationId }) {
        const { data } = await instance.get(`${base}/${applicationId}`);
        return data;
    },
    async getFtm() {
        const { data } = await instance.get(`api/quantitative-analysis/?locationName=System%20Input&no_limit=true`);
        return data;
    },
    async createJobRatings({ applicationId, body }) {
        const { data } = await instance.post(`${base}/${applicationId}`, body);
        return data;
    },
    async updateJobRatings({ applicationId, body }) {
        const { data } = await instance.put(`${base}/${applicationId}`, body);
        return data;
    },
    async deleteJobRatings({ applicationId, ratingId }) {
        const { data } = await instance.delete(`${base}/${applicationId}/${ratingId}`);
        return data;
    },
});

