import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import api from "../../api";
import {getAllComunicationsFetch, sendMessageToApplicantFetch} from "../../redux/thunks/communicationsThunks";
import Button from "../base/Button";
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import ModalTemplate from '../base/ModalTemplate';
import PrimaryButton from "../base/PrimaryButton";
import SelectBox from "../base/SelectBox";
import TextareaFieldHorisontal from '../base/TextareaFieldHorisontal';
import CommunicationTable from "../../pages/SBM01/parts/CommunicationTable";
import PageLoader from "../PageLoader";
import {getSubscriberRole} from "../../redux/slices/applicationSlice";
import MultiSelect from "../base/MultiSelect";
import {toast} from "react-toastify";

const EmailModal = ({
                        open,
                        handleClose,
                        tableHeding = false,
                        tableHedingButton = false,
                        footerBorderTop = true,
                        data = [],
                        selectedApplicants = []
                    }) => {
    const dispatch = useDispatch();
    const {jobId, applicationId: appId} = useParams();
    const {isLoading: isCommunicationLoading} = useSelector(state => state.communications);
    const subscriberRole = useSelector(getSubscriberRole);

    const [applicants, setApplicants] = useState(
        selectedApplicants.map(one => ({...one, value: one.email, label: one.fullName}))
    );

    const applicationId = (selectedApplicants.length > 0 && (appId || selectedApplicants[0].id)) || null;

    const [jobData, setJobData] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [applicationData, setApplicationData] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        content: '',
        subject: '',
        templateId: ""
    });

    const [formErrors, setFormErrors] = useState({});

    const getJobApplicationById = async () => {
        if (!jobId || !applicationId) return;
        setIsLoading(true);
        try {
            const jobRes = await api.jobs.getJobsById(jobId);
            setJobData(jobRes?.data);

            const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
            setApplicationData(res?.data);

            setFormData(prev => ({
                ...prev,
                name: res?.data?.fullName || '',
                phone: res?.data?.phone || '',
                email: res?.data?.email || '',
                subject: res?.data?.subject || '',
            }));

            const resTemplates = await api.communications.getAllTemplates(jobRes?.data?.companyId, "email");
            setTemplates(resTemplates?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getJobApplicationById();
    }, [jobId, applicationId]);

    useEffect(() => {
        if (applicationId) {
            dispatch(getAllComunicationsFetch({applicationId}));
        }
    }, [applicationId]);

    const validateForm = () => {
        const newErrors = {};
        if (formData.email && !(/\S+@\S+\.\S+/.test(formData.email))) {
            newErrors.email = "Invalid Email";
        }
        if (!formData.content) {
            newErrors.content = "Content empty";
        }
        setFormErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setFormErrors(prevErrors => ({...prevErrors, [name]: ''}));
    };

    const handleSumbit = async () => {
        if (!applicants || applicants.length === 0) {
            toast.error("No applicants selected.");
            return;
        }

        if (!validateForm()) return;

        if (applicants.length > 1) {
            await Promise.all(applicants.map(async (one) => {
                return dispatch(sendMessageToApplicantFetch({
                    applicationId,
                    body: {
                        type: "email",
                        content: formData.content,
                        subject: formData.subject,
                        templateId: formData.templateId,
                        name: one.fullName,
                        phone: one.phone,
                        email: one.email
                    }
                }));
            }));
            toast.success("Message sent to all selected applicants successfully");
            handleClose();
            return;
        }
        const res = await dispatch(sendMessageToApplicantFetch({
            applicationId,
            body: {
                type: "email",
                content: formData.content,
                subject: formData.subject,
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                templateId: formData.templateId,
            }
        }));

        if (res) {
            setFormData(prev => ({...prev, content: ""}));
            toast.success("Message sent successfully");
        }
        dispatch(getAllComunicationsFetch({applicationId}));
    };

    const selectTemplate = (value) => {
        setFormData(prevState => ({...prevState, templateId: value}));
        const item = templates.find(one => one.id === value)
        if (item) {
            setFormData(prevState => ({...prevState, content: item?.content, subject: item?.subject }));
        }
    };

    return (
        <ModalTemplate open={open} onClick={handleClose} modalSize="max-w-[772px]">
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Email</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            {
                !isLoading ?
                    <>
                        <div className="modal__body">
                            <div className="w-full">
                                <div className="flex flex-col gap-[16px] mb-[32px]">
                                    {selectedApplicants.length === 1 && (
                                        <>
                                            <InputFieldHorisontal
                                                inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                label="Name"
                                                labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                type="text" name="name" id="name"
                                                value={formData?.name}
                                                onChange={handleChange}
                                                placeholder="John Doe"
                                                error={formErrors.name}
                                            />
                                            <InputFieldHorisontal
                                                inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                label="Phone Number"
                                                disabled={true}
                                                labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                type="text" name="phone" id="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="123-453-1232"
                                                error={formErrors.phone}
                                            />
                                            <InputFieldHorisontal
                                                inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                label="Email"
                                                labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                type="text" name="email" id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder="example@mail.com"
                                                error={formErrors.email}
                                            />
                                        </>
                                    )}
                                    {selectedApplicants.length > 1 && (
                                        <div className="flex items-center gap-[14px]">
                                            <p className="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]">
                                                Applicants
                                            </p>
                                            <div className="select-multi-value-container--secondary">
                                                <MultiSelect
                                                    options={selectedApplicants.map(one => ({
                                                        ...one,
                                                        label: one.fullName,
                                                        value: one.email
                                                    }))}
                                                    selectStyle="secondary"
                                                    placeholder='Add applicants'
                                                    value={applicants}
                                                    onChange={val => {
                                                        setApplicants(val)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="flex max-md:flex-col md:items-center gap-[12px] md:gap-[16px] flex-1">
                                        <label
                                            className="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]">
                                            Template
                                        </label>
                                        <SelectBox
                                            options={
                                                templates
                                                    ? templates.map(one => ({label: one.title, value: one.id}))
                                                    : []
                                            }
                                            onSelect={selectTemplate}
                                            value={formData.templateId}
                                        />
                                    </div>
                                    <InputFieldHorisontal
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Subject"
                                        labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="subject" id="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        placeholder="Enter subject"
                                    />
                                    <TextareaFieldHorisontal
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Content"
                                        labelClassName="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="content" id="content"
                                        value={formData.content}
                                        onChange={handleChange}
                                        rows={4}
                                        error={formErrors.content}
                                    />
                                </div>
                                {selectedApplicants.length === 1 && (
                                    <>
                                        {tableHeding && (
                                            <div className="flex items-center justify-between gap-2">
                                                <div
                                                    className="text-[18px] md:text-[20px] font-semibold leading-8 text-zinc-800">
                                                    Communication History
                                                </div>
                                                {tableHedingButton && (
                                                    <Button
                                                        className={'text-white bg-green-500 md:min-w-[122px] min-h-[52px] text-[18px]'}>
                                                        Dial
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                        <div className="flex flex-col mt-[16px] gap-[32px]">
                                            {/*{communications.map(dataItem =>*/}
                                                <div className="flex flex-col self-stretch max-h-[300px]">
                                                    {/*<h2 className="w-full text-[18px] font-semibold text-stone-500 max-md:max-w-full mb-[10px]">*/}
                                                    {/*    {dataItem.name}*/}
                                                    {/*</h2>*/}
                                                    <CommunicationTable isSorted={false} type={"email"}/>
                                                </div>
                                            {/*)}*/}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {subscriberRole !== "interviewer" && (
                            <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                                <Button
                                    className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'}
                                    onClick={handleClose}
                                >
                                    <span className="underline">Cancel</span>
                                </Button>
                                <PrimaryButton
                                    className={'w-[122px] !h-[52px] !text-[18px]'}
                                    onClick={handleSumbit}
                                    isLoading={isCommunicationLoading}
                                >
                                    Submit
                                </PrimaryButton>
                            </div>
                        )}
                    </>
                    :
                    <PageLoader/>
            }
        </ModalTemplate>
    );
};

export default EmailModal;
