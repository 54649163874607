import React from "react";
import Step4 from "../NewJobDetails/Step4";
import NextStepSection from "./NextStepSection";

const Pipelines = ({
    payload,
    setPayload,
    setContactFormOpen,
    industriesList,
    qaPresetAttributesSelected,
    setQaPresetAttributesSelected,
    save,
    statusToggle,
}) => {
    return (
        <div className="mt-[40px] flex flex-col gap-10">
            <Step4
                payload={payload}
                setPayload={setPayload}
                setContactFormOpen={setContactFormOpen}
                industriesList={industriesList}
                qaPresetAttributesSelected={qaPresetAttributesSelected}
                setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                isEdit={true}
                bottomPaste={<div className="mb-[50px]">
                    <NextStepSection
                        save={save}
                        statusToggle={statusToggle}
                        payload={payload}
                    />
                </div>}
            />
        </div>
    )
}

export default Pipelines
