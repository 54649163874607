import React, {useEffect, useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import {Footer} from "../components/parts/Footer";
import {useDispatch, useSelector} from "react-redux";
import { getUser} from "../redux/slices/applicationSlice";
import {fetchAllIndustries, fetchUpdateBuildCompanies} from "../redux/thunks/companiesThunks";
import SelectBox from "../components/base/SelectBox";
import TextareaField from "../components/base/TextareaField";
import {toast} from "react-toastify";
import api from "../api";
import {format} from "date-fns";
import PrimaryButton from "../components/base/PrimaryButton";
import {companySizeOptions} from "../data/constants";

const SBM05_1_1 = () => {

    const dispatch = useDispatch()
    const me = useSelector(getUser)
    const [hasShownToast, setHasShownToast] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const response = params.get("sub_response");

        if (response === "success" && !hasShownToast) {
            toast.success("Payment was successful!");
            setHasShownToast(true);
        }
    }, [hasShownToast]);


    const [crumbs, setCrumbs] = useState(['Subscriber Dashboard', 'Settings', 'Admin Settings'])
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOwner, setIsOwner] = useState(false)
    const [planInfo, setPlanInfo] = useState(null)
    const [newPlan, setNewPlan] = useState(null)

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        industrieId: '',
    });

    const [companyData, setCompanyData] = React.useState({
        name: '',
        companySize: '',
        description: '',
        industrieId: '',
    })

    const getInfo = async (id) => {
        const res = await api.companies.planCompanyInfo(id);
        if (res?.success) {
            setPlanInfo(res?.data)
        }
    };

    useEffect(() => {
        if (me) {
            if (me?.companies?.[0]?.role === "admin") {
                getInfo(me?.company?.id)
            }

            if (me?.companies?.length) {
                setIsOwner(true)
                setCompanyData(prev => ({
                    name: me?.company?.name,
                    description: me?.companies[0].company?.description,
                    industrieId: me?.companies[0].company?.industrieId || me?.companies[0].company?.industry?.id || '',
                    companySize: me?.companies[0].company?.companySize,
                }))
                // setCrumbs(prev => ([...prev, 'Company Info']))
            } else {
                // setCrumbs(prev => ([...prev, 'Account Info']))
            }
        }
    }, [me])


    const [industryOptions, setIndustryOptions] = React.useState([
        {label: "Choose", value: ''}
    ])

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setErrors(prevState => ({...prevState, [name]: false}))
    };
    const handleChangeCompany = (e) => {
        const {name, value} = e.target;
        setCompanyData(prevState => ({...prevState, [name]: value}));
        setErrors(prevState => ({...prevState, [name]: false}))
    };

    useEffect(() => {

        const req = async () => {
            const inds = await dispatch(fetchAllIndustries())
            setIndustryOptions([{label: "Choose", value: ''}, ...inds.payload.map(el => ({
                label: el?.name,
                value: el?.id
            }))])
            // const plansRes = await api.companies.getAllPricingPlans();
            // if (plansRes?.success) {
            //     setAllPlans(plansRes?.data)
            // }
        }
        req()

    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault()
        let fields = []
        if (isOwner) {
            fields = [...fields, ...Object.entries(companyData)]
        }
        let anyErrors = false
        fields.forEach(el => {
            if (!el[1]) {
                setErrors(prevState => ({...prevState, [el[0]]: true}))
                anyErrors = true
            }
        })
        if (anyErrors) return

        setIsLoading(true)
        if (isOwner) {
            const companyReq = await dispatch(fetchUpdateBuildCompanies({...companyData, id: me?.company?.id}))
            if (!companyReq?.payload?.success) {
                toast.error(companyReq?.payload?.message)
            } else {
                toast.success('Changes Saved successfully!')
                setCompanyData({
                    name: companyReq?.payload?.data?.name,
                    description: companyReq?.payload?.data?.description,
                    industrieId: companyReq?.payload?.data?.industrieId,
                    companySize: companyReq?.payload?.data?.companySize,
                })
            }
        }

        setIsLoading(false)

    }

    const handleCancel = async () => {
        try {
            const res = await api.companies.cancelPricingPlan(me?.company?.id);
            if (res?.success) {
                getInfo(me?.company?.id)
                toast.success("Changes Saved successfully!")
            }
        } catch (e) {
            console.log(e)
        }
    };

    const savePlan = async () => {
        try {
            const res = await api.companies.selectPricingPlan(me?.company?.id, newPlan?.id || planInfo?.pricingPlanId);
            if (res?.success) {
                window.location.href = res?.data?.paymentSession?.paymentURL
                toast.success("Plan Saved successfully!")
            }
        } catch (e) {
            console.log(e)
        }
    };

    const nextPaymentDate = planInfo?.upcomingInvoice?.next_payment_attempt
        ? new Date(planInfo?.upcomingInvoice?.next_payment_attempt * 1000)
        : null;
    const endDate = planInfo?.endDate ? new Date(planInfo?.endDate) : null;

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs}/>
            <main
                className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1272px] px-4 w-full m-auto flex-1">
                <div className="flex flex-col justify-center bg-white">
                    <section
                        className="flex flex-col justify-between items-center px-8 py-9 lg:px-16 lg:py-11 bg-white rounded-md shadow-def">
                        <header className="text-4xl md:text-5xl font-bold text-sky-950 mb-12 capitalize">
                            {planInfo?.title} {" "} {planInfo?.isDemo && '(Demo)'} {" "}
                            {/*{planInfo?.price &&*/}
                            {/*    <span>${planInfo?.price} / Month</span>}*/}
                        </header>

                        <form className="w-full" onSubmit={handleSubmit}>
                            <div className="grid lg:grid-cols-2 lg:gap-[60px]">
                                <div className="w-full">
                                    {/* <InputField
                                        label="Work Email" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        type="email" name="email" id="email" placeholder="youremailaddress@mail.com"
                                        value={formData.email} onChange={handleChange} error={errors.email} disabled={isLoading}
                                    /> */}
                                    {/* <InputField
                                        label="First Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="firstName" id="firstName" placeholder="First name" disabled={isLoading}
                                        value={formData.firstName} onChange={handleChange} error={errors.firstName}
                                    /> */}
                                    {/* <InputField
                                        label="Last Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="lastName" id="lastName" placeholder="Last name" disabled={isLoading}
                                        value={formData.lastName} onChange={handleChange} error={errors.lastName}
                                    /> */}
                                    {isOwner && <InputField
                                        label="Company Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="name" id="name" placeholder="Company name" disabled={isLoading}
                                        value={companyData?.name} onChange={handleChangeCompany} error={errors?.name}
                                    />}

                                    {isOwner && <SelectBox
                                        options={companySizeOptions}
                                        label="Company Size"
                                        onSelect={val => {
                                            setCompanyData(prevState => ({
                                                ...prevState,
                                                'companySize': val
                                            }))
                                            setErrors(prevState => ({
                                                ...prevState,
                                                'companySize': false
                                            }));

                                        }}
                                        className={'!mt-[10px] '}
                                        value={companyData?.companySize} svg={2}
                                        error={errors?.companySize} disabled={isLoading}
                                        labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}

                                    />}

                                    {isOwner && <div className="lg:gap-5 mt-[18px]">
                                        {/* <InputField
                                            label="Job Title"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                            name="jobTitle" id="jobTitle" placeholder="Job title" disabled={isLoading}
                                            value={companyData.jobTitle} onChange={handleChangeCompany} error={errors.jobTitle}
                                        /> */}


                                        <SelectBox options={industryOptions} label="Industry"
                                                   onSelect={val => {
                                                       setCompanyData(prevState => ({...prevState, 'industrieId': val}))
                                                       setErrors(prevState => ({...prevState, 'industrieId': false}));

                                                   }}
                                                   className={'!mt-[10px] '}
                                                   value={companyData?.industrieId} svg={2}
                                                   error={errors?.industrieId} disabled={isLoading}
                                                   labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}

                                        />
                                    </div>}
                                    {isOwner && <TextareaField
                                        wrapperClassName={"mt-5"}
                                        label="Company Description"
                                        inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="description" id="description" placeholder="Company description"
                                        disabled={isLoading}
                                        value={companyData?.description} onChange={handleChangeCompany}
                                        error={errors?.description}

                                    />}
                                </div>
                                <div className="w-full">

                                    {/*{allPlans && <div className="flex flex-col gap-[8px] flex-1 mb-6 ">*/}
                                    {/*    <SelectBox*/}
                                    {/*        options={allPlans?.map(plan => ({label: plan.title, value: plan.id})) || []}*/}
                                    {/*        label="Pricing Plan"*/}
                                    {/*        onSelect={val => {*/}
                                    {/*            setNewPlan(allPlans?.find(plan => plan.id === val))*/}
                                    {/*        }}*/}
                                    {/*        className={'!mt-[10px] '}*/}
                                    {/*        value={newPlan?.id || planInfo?.pricingPlanId || null} svg={2}*/}
                                    {/*        labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}*/}
                                    {/*    />*/}
                                    {/*</div>}*/}
                                    {planInfo?.features &&
                                        <div className="flex flex-col gap-[8px] flex-1 mb-6 ">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">Features</label>
                                            <div className="min-h-[55px]"
                                                 dangerouslySetInnerHTML={{__html:  planInfo?.features}}></div>
                                        </div>}
                                    {planInfo?.price &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">Price</label>
                                            <div>
                                                ${ planInfo?.price} / Month
                                            </div>
                                        </div>}

                                    { planInfo?.period &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">Period</label>
                                            <div className="capitalize">
                                                {newPlan?.period || planInfo?.period}
                                            </div>
                                        </div>
                                    }
                                    { planInfo?.endDate && planInfo?.status !== 'active' &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">End
                                                Date</label>
                                            <div className="capitalize">
                                                {format(planInfo?.endDate, "MM/dd/yyyy")}
                                            </div>
                                        </div>}
                                    {planInfo?.endDate && nextPaymentDate && endDate && nextPaymentDate < endDate &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label className="text-[18px] font-bold text-zinc-800 leading-[18px]">
                                                Next Payment{" "}
                                                {planInfo?.upcomingInvoice?.amount_remaining
                                                    ? `${planInfo?.upcomingInvoice?.amount_remaining / 100}$`
                                                    : ""}
                                            </label>

                                            {nextPaymentDate < endDate ?
                                                <div className="">
                                                    Paid to ({format(nextPaymentDate, "MM/dd/yyyy")})
                                                </div> :
                                                <div className="capitalize">
                                                    {format(planInfo?.endDate, "MM/dd/yyyy")}
                                                </div>
                                            }
                                        </div>}
                                        {planInfo?.endDate && nextPaymentDate && endDate && nextPaymentDate <= endDate &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label className="text-[18px] font-bold text-zinc-800 leading-[18px]">
                                                Next Payment{" "}
                                                {planInfo?.upcomingInvoice?.amount_remaining
                                                    ? `${planInfo?.upcomingInvoice?.amount_remaining / 100}$`
                                                    : ""}
                                            </label>

                                            {nextPaymentDate < endDate ?
                                                <div className="">
                                                    Paid to ({format(nextPaymentDate, "MM/dd/yyyy")})
                                                </div> :
                                                <div className="capitalize">
                                                    {format(planInfo?.endDate, "MM/dd/yyyy")}
                                                </div>
                                            }
                                        </div>}
                                    {planInfo?.endDate && planInfo &&
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6 ">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">Status</label>

                                            <div
                                                className={`uppercase font-bold ${planInfo?.status === "active" ? "text-green-500" : planInfo?.status === "pending" ? "text-yellow-500" : "text-red-600"}`}>
                                                {planInfo?.status}
                                            </div>
                                        </div>}

                                    { planInfo?.session?.url && planInfo?.status === "pending" && (
                                        <div className="flex flex-col gap-[8px] flex-1 mt-[18px] mb-6">
                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px]">Payment</label>
                                            <div>
                                                Please pay <span
                                                className="font-semibold">${planInfo?.price}</span>
                                                <PrimaryButton
                                                    onClick={() => window.location.href = planInfo?.session?.url}
                                                    className="btn btn-secondary !h-fit p-1 !text-[14px] px-[20px]"
                                                >
                                                    Pay
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {me?.companies?.[0]?.role === "admin" &&
                                <div className="flex justify-center items-center gap-[22px] mt-[24px]">
                                    { planInfo?.status === "canceled" &&
                                        <button
                                            type="button"
                                            onClick={savePlan}
                                            className={`${isLoading ? 'disabled' : ''} min-w-[236px] max-lg:w-full text-[18px] font-[700] uppercase text-[#fff] justify-center items-center px-16 h-[50px] rounded-md bg-[#37B34A]  hover:opacity-70`}>
                                            Upgrade Plan
                                        </button>}
                                    {planInfo && !planInfo?.isDemo && planInfo?.status === "active" &&
                                        <button
                                            type="button"
                                            onClick={handleCancel}
                                            className={`${isLoading ? 'disabled' : ''} min-w-[236px] max-lg:w-full text-[18px] font-[700] uppercase text-[#fff] justify-center items-center px-16 h-[50px] rounded-md bg-red-600 hover:opacity-70`}>
                                            Cancel Plan
                                        </button>}
                                    <button
                                        className={`${isLoading ? 'disabled' : ''} min-w-[236px] max-lg:w-full text-[18px] font-[700] uppercase text-[#fff] justify-center items-center px-16 h-[50px]  rounded-md bg-[#37B34A] hover:opacity-70`}>
                                        Save
                                    </button>
                                </div>}
                        </form>
                    </section>
                </div>
            </main>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SBM05_1_1;
