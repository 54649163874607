import {useCallback, useState, createRef, useEffect, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import LoadIcon from "../../assets/img/load-icon.svg";
import ReplaceIcon from "../../assets/img/replace-icon.svg";
import DeleteIcon from "../../assets/img/delete-icon.svg";
import RecordIcon from "../../assets/img/icon-record.svg";
import {Link} from 'react-router-dom';
import ModalTemplate from './ModalTemplate';
import Button from './Button';
import PrimaryButton from './PrimaryButton';

const ButtonWithLabel = ({onClick, label, altText}) => (
    <button
        type='button'
        className="flex gap-2 items-center justify-center px-8 py-2.5 rounded-md bg-neutral-200 hover:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2"
        onClick={onClick}
    >
        <img loading="lazy" src={label.src} alt={altText} className="w-6 h-6"/>
        <span>{label.text}</span>
    </button>
);

const VideoRecorderModal = ({open, onClose, onSave}) => {
    const [recording, setRecording] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);

    const [stream, setStream] = useState(null)

    const startRecording = async () => {
        try {
            if (videoUrl) {
                setVideoUrl(null)
            }
            if (!stream) return
            // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // videoRef.current.srcObject = stream;

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(chunksRef.current, {type: 'video/webm'});
                setVideoFile(new File([blob], "recorded", {
                    type: "video/mp4"
                }))
                const videoUrl = URL.createObjectURL(blob);
                setVideoUrl(videoUrl);
                chunksRef.current = [];
                stream.getTracks().forEach(track => track.stop());
            };

            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setRecording(true);
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };

    const handleSave = () => {
        if (!videoUrl && videoFile) return
        onSave(videoFile)
        onClose && onClose()
    }

    const init = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        videoRef.current.srcObject = stream;
        setStream(stream)
    }
    useEffect(() => {
        init()
    }, [])
    return (
        <ModalTemplate open={open}>
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Webcam Video Recorder</h2>
                <button className="btn btn-close" onClick={onClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                {!videoUrl &&
                    <video ref={videoRef} autoPlay playsInline className='w-full aspect-video'></video>
                }
                {videoUrl && (
                    <video src={videoUrl} controls className='w-full aspect-video'></video>
                )}
            </div>
            <div className={`modal__footer modal__footer-border !justify-between`}>
                <Button className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'}
                        onClick={onClose}>
                    <span className="underline">Cancel</span>
                </Button>
                <div className='flex items-center gap-2'>
                    {recording ? (
                        <PrimaryButton className={"px-4 !h-14 bg-red-600"} type='button' onClick={stopRecording}>Stop
                            Recording</PrimaryButton>
                    ) : (
                        <PrimaryButton disabled={!stream}
                                       className={`px-4 !h-14 ${!stream ? "opacity-70" : ""} ${videoUrl ? "bg-red-600" : ""}`}
                                       type='button'
                                       onClick={startRecording}>{videoUrl ? "Restart" : "Start"} Recording</PrimaryButton>
                    )}
                    {
                        videoUrl &&
                        <PrimaryButton className={"px-4 !h-14"} type='button' onClick={handleSave}>Save</PrimaryButton>
                    }
                </div>
            </div>
        </ModalTemplate>
    );
};

const VideoRecorderInline = ({onSave}) => {
    const [recording, setRecording] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);

    const init = async () => {
        try {
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
            if (videoRef.current) {
                videoRef.current.srcObject = newStream;
            }
            setStream(newStream);
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    useEffect(() => {
        init();

        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [stream]);

    const startRecording = async () => {
        try {
            if (videoUrl) {
                setVideoUrl('');
                setVideoFile(null);
                await init();
            }

            if (!stream) return;

            const mediaRecorder = new MediaRecorder(stream);
            chunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(chunksRef.current, {type: 'video/webm'});
                const file = new File([blob], 'recordedVideo.webm', {
                    type: 'video/webm',
                });
                setVideoFile(file);

                const localUrl = URL.createObjectURL(blob);
                setVideoUrl(localUrl);
                chunksRef.current = [];
            };

            mediaRecorderRef.current = mediaRecorder;
            mediaRecorder.start();
            setRecording(true);
        } catch (err) {
            console.error('Error starting recording:', err);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setRecording(false);
        stream?.getTracks()?.forEach((track) => track?.stop());
    };

    const handleSave = () => {
        if (!videoUrl || !videoFile) return;
        onSave?.(videoFile);
    };

    return (
        <div className="border border-neutral-300 rounded p-4 w-full max-w-[600px]">
            <h3 className="font-bold mb-2 text-lg">Video Recorder</h3>

            {!videoUrl && (
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    className="w-full aspect-video"
                />
            )}

            {videoUrl && (
                <video src={videoUrl} controls className="w-full aspect-video"/>
            )}

            <div className="flex gap-3 mt-4">
                {recording ? (
                    <PrimaryButton
                        className="px-4 !h-14 bg-red-600"
                        type="button"
                        onClick={stopRecording}
                    >
                        Stop Recording
                    </PrimaryButton>
                ) : (
                    <PrimaryButton
                        disabled={!stream}
                        className={`px-4 !h-14 ${
                            !stream ? 'opacity-70' : ''
                        } ${videoUrl ? 'bg-red-600' : ''}`}
                        type="button"
                        onClick={startRecording}
                    >
                        {videoUrl ? 'Restart' : 'Start'} Recording
                    </PrimaryButton>
                )}

                {videoUrl && (
                    <PrimaryButton
                        className="px-4 !h-14"
                        type="button"
                        onClick={handleSave}
                    >
                        Save
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};


const FileInputField = ({
                            fileProp, boxClassName = "", handleRemove = () => {
    }, loadBtnShow = false, handleChange = () => {
    }, acceptProp, hideActions, isVideo, record, recordModal = true
                        }) => {
    const inputRef = createRef(null);
    const [isVideoRecorderModalOpen, setVideoRecorderModalOpen] = useState(false)
    const [file, setFile] = useState(fileProp || null);

    useEffect(() => {
        if (fileProp instanceof File || fileProp instanceof Blob) {
            setFile(Object.assign(fileProp, {
                preview: URL?.createObjectURL(fileProp)
            }));
        } else {
            setFile(null)
        }
    }, [fileProp]);

    useEffect(() => {
        handleChange && handleChange(file)
    }, [file]);


    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setFile(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));

    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: acceptProp ? acceptProp : {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        multiple: false,
    });

    return (
        <div
            className={`box-border flex flex-col items-start justify-center w-full lg:max-w-[332px] ${boxClassName ? boxClassName : ''}`}
            ref={inputRef}>
            <div {...getRootProps()}
                 className={`flex flex-col items-center justify-center rounded-[6px] p-[8px] md:p-[20px] cursor-pointer hover:bg-neutral-50 border border-solid border-neutral-300 mb-[16px] w-full  ${file ? '!h-[1px] !opacity-[0] !p-[0] !mb-[0] !overflow-hidden' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div className="flex items-center gap-[20px] min-h-[44px]">
                        <p className="text-neutral-700">Drop the images here ...</p>
                    </div>
                ) : (
                    <div className="flex items-center w-full">
                        <button
                            type='button'
                            className="flex gap-2 flex-1 items-center justify-center px-2 py-2.5 rounded-md bg-neutral-200"
                        >
                            Choose File
                        </button>
                        <p className="text-neutral-700 flex-1 text-center px-2">

                            {!file ? "No file choses" : "Drag 'n' drop some files here, or click to select files"}
                        </p>
                    </div>
                )}
            </div>
            {file && <>
                <aside className="flex flex-col gap-4 mb-[16px] w-full">
                    {file && (
                        <div
                            className="flex gap-5 max-md:flex-col max-md:gap-0 border border-solid border-stone-300 p-[20px]">
                            {file.type === 'application/pdf' ? <span className="flex w-[26px] aspect-square">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22.5641 4.73015L20.7711 3.5114C20.275 3.17546 19.5953 3.30437 19.2633 3.79656L10.4742 16.7575L6.43518 12.7184C6.01331 12.2966 5.32581 12.2966 4.90393 12.7184L3.36877 14.2536C2.9469 14.6755 2.9469 15.363 3.36877 15.7887L9.57971 21.9997C9.92737 22.3473 10.4742 22.613 10.9664 22.613C11.4586 22.613 11.9547 22.3044 12.275 21.8395L22.8531 6.23406C23.1891 5.74187 23.0602 5.06609 22.5641 4.73015Z"
                                        fill="#06D6A0"/>
                                </svg>
                            </span> :
                                <div className="flex flex-col max-md:ml-0 max-md:w-full">
                                    <div className="mx-auto bg-neutral-200 max-h-[92px] max-w-[92px] max-md:mt-10">
                                        {
                                            isVideo
                                                ?
                                                <video src={file.preview} playsInline
                                                       className='w-full aspect-video'></video>
                                                :
                                                <img src={file.preview} alt="Preview"
                                                     className="w-full max-h-[100%] object-cover rounded-md"/>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
                                <div className="self-stretch my-auto text-base text-zinc-800 max-md:mt-10">
                                    {file.type === 'application/pdf' ?
                                        <Link className='underline underline-offset-1 hover:no-underline'
                                              target='_blank' to={file.preview}>{file.name}</Link>
                                        : file.name}
                                </div>
                            </div>
                        </div>
                    )}
                </aside>
                {!hideActions ?
                    <div className="flex gap-4">
                        {loadBtnShow ?
                            <ButtonWithLabel
                                onClick={() => {
                                }}
                                label={{src: LoadIcon, text: 'Load'}}
                                altText="Load file button"
                            /> : null}
                        <ButtonWithLabel
                            onClick={() => {
                                inputRef?.current.querySelector('input').click();
                            }}
                            label={{src: ReplaceIcon, text: 'Replace'}}
                            altText="Replace file button"
                        />
                        <ButtonWithLabel
                            onClick={() => {
                                handleRemove && handleRemove()
                                setFile(null);
                            }}
                            label={{src: DeleteIcon, text: 'Delete'}}
                            altText="Delete file button"
                        />
                    </div>
                    : null
                }
            </>}
            {
                isVideo && isVideoRecorderModalOpen && recordModal &&
                <VideoRecorderModal
                    open={isVideoRecorderModalOpen}
                    onClose={() => setVideoRecorderModalOpen(false)}
                    onSave={(file) => handleChange(file)}
                />
            }
            {
                isVideo && record &&
                <ButtonWithLabel
                    onClick={() => setVideoRecorderModalOpen(prevState => !prevState)}
                    label={{
                        src: RecordIcon,
                        text: 'Record video'
                    }}
                    altText="Record video"
                />
            }

            {isVideo && record && isVideoRecorderModalOpen && !recordModal && (
                <div className="mt-4 w-full">
                    <VideoRecorderInline onSave={(file) => {
                        handleChange(file)
                        setVideoRecorderModalOpen(false)
                    }}/>
                </div>
            )}
        </div>
    )
};

export default FileInputField;
