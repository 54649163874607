import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useNavigation, useSearchParams} from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {Footer} from "../components/parts/Footer";
import {Pagination} from "./SBM01_2";
import PageLoader from "../components/PageLoader";
import {format} from "date-fns";
import api from "../api";
import SelectField from "../components/base/SelectField";
import {fetchAllIndustries} from "../redux/thunks/companiesThunks";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as SearchIcon} from "../assets/img/search-icon-grey.svg";

function CompaniesTracker({data}) {
    const navigate = useNavigate()

    const handleClick = (id) => {
        navigate("/SUP1_1_3_1/" + id)
    }

    return (
        <>
            <div className="w-full flex flex-col gap-7 mt-9 lg:hidden">
                {data?.map((post, index) => (
                    <div className="flex flex-col gap-3" key={index}>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Company Name</div>
                        </div>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Email</div>
                        </div>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Phone</div>
                        </div>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Date Request</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="overflow-x-auto max-lg:hidden">
                <table className="w-full mt-8">
                    <thead className="text-3xl text-zinc-800">
                    <tr>
                        <th className="px-5 py-2 text-left">Company Name</th>
                        <th className="px-5 py-2 text-left">Email</th>
                        <th className="px-5 py-2 text-left">Phone</th>
                        <th className="px-5 py-2 text-left">Date Request</th>
                    </tr>
                    </thead>
                    <tbody className="text-2xl text-sky-900">
                    {data?.map((subscription, index) => (
                        <tr
                            key={index}
                            onClick={() => handleClick(subscription?.id)}
                            className="cursor-pointer"
                        >
                            <td className="px-5 py-2 text-left">
                                <div className="flex items-center gap-[20px]">
                                    <div className="w-[12px] h-[12px] bg-[#37B34A]"/>
                                    <div className="font-[700] text-[26px]">{subscription?.name}</div>
                                </div>
                            </td>
                            <td className="px-5 py-2 text-left font-[700] text-[26px]">{subscription?.email}</td>
                            <td className="px-5 py-2 text-left font-[700] text-[26px]">{subscription?.phoneNumber}</td>
                            <td className="px-5 py-2 text-left font-[700] text-[26px]">{format(subscription?.createdAt || new Date(), "MMMM dd yyyy")}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function DemoCompanyPage() {
    const dispatch = useDispatch();
    const [companies, setCompanies] = useState([])
    const {industries, isLoading: isIndustriesLoading} = useSelector(state => state.companies);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState(null)
    const [formData, setFormData] = useState({
        industry: searchParams.get('industry') || '',
    });
    const [search, setSearch] = useState(searchParams.get('search') || '');

    const getCompanies = async () => {
        setIsLoading(true)
        try {
            const res = await api.companies.getAllDemoCompanies(searchParams.toString());
            setCompanies(res?.data || [])
            setPagination(res?.pagination || null)
            setIsLoading(false)
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(fetchAllIndustries());
        setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("sort_by", "id");
            newParams.set("sort_as", "desc");
            return newParams;
        })
    }, [])
    const industryOptions = !isIndustriesLoading ? [
        {value: '', label: 'All Departments'},
        ...industries.map(item => ({label: item?.name, value: item?.id}))
    ] : [];

    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set(name, value);
            newParams.delete("page");
            return newParams;
        });
    };

    useEffect(() => {
        const newFormData = {
            industry: searchParams.get('industry') || '',
        };
        setFormData(newFormData);
        setSearch(searchParams.get('search') || '');
    }, [searchParams]);
    useEffect(() => {
        if (!searchParams.toString()) return
        getCompanies()
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set("page", page);
            return newParams;
        });
    };


    return (
        <section
            className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
                <h1 className="text-4xl font-bold max-md:text-center">Demo Company Requests</h1>
                <div
                    className="flex flex-col self-stretch py-9 px-8 lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex max-lg:flex-col gap-[14px] lg:gap-8 w-full text-neutral-400">
                        <div className="flex items-center w-full lg:w-64">
                            <SelectField
                                inputBg="bg-white rounded-md border border-solid border-zinc-300 min-w-[300px]"
                                name="industry"
                                value={formData.industry}
                                onChange={handleChange}
                                options={industryOptions}
                            />
                        </div>
                        <div className="flex items-center max-lg:w-full lg:ml-auto">
                            <div
                                className="flex items-center flex-auto bg-white rounded-md border border-solid border-zinc-300 outline-none pr-4 mb-[18px]"
                            >
                                <input
                                    type="search"
                                    className="flex-1 px-4 py-2 rounded-md outline-none h-[52px] lg:h-[54px]"
                                    placeholder="Cleaning Technician"
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                        if (e.target.value.length === 0) {
                                            handleChange({target: {name: "search", value: e.target.value}})
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleChange({target: {name: "search", value: search}})
                                        }
                                    }}
                                    id="search"
                                    name='search'
                                    value={search}
                                />
                                <span className="flex cursor-pointer"
                                      onClick={() => handleChange({target: {name: "search", value: search}})}>
                                        <SearchIcon/>
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full"/>
                    {!isLoading ?

                        companies.length ?
                            <div>
                                <CompaniesTracker data={companies}/>
                            </div>
                            :
                            <div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No
                                Requests
                                yet</div>
                        :
                        <PageLoader/>}
                </div>
                {pagination && pagination?.maxPages >= 2 && <Pagination
                    currentPage={pagination.page}
                    totalPages={pagination.maxPages}
                    onPageChange={handlePageChange}
                />}
            </div>
        </section>
    );
}


function NavigationItem({children, onClick, isSelected, route}) {
    const baseClasses = `lg:px-5 px-4 py-2 cursor-pointer border-b-4  whitespace-nowrap`
    return (
        <>
            <NavLink
                to={route}
                className={({isActive}) => (isActive ? `border-green-500 ${baseClasses}` : `border-transparent ${baseClasses}`)}
            >
                {children}
            </NavLink>
        </>
    );
}

function NavigationBar() {
    const navigationItems = [
        {id: 1, text: "Current Subscribers", route: "/SUP01"},
        {id: 2, text: "Company Requests", route: "/SUP1_1_4"},
        {id: 3, text: "Demo Company Requests", route: "/SUP1_1_3"},
        {id: 4, text: "Update Public Solution Page", route: "/SUP01_2"},
        {id: 5, text: "Update Subscriber Support Center", route: "/SUP01_3"},
        {id: 5, text: "Public Home Pages", route: "/SUP01_4"},
        {id: 7, text: "Subscribe", route: "/Sup1_5_2"},
    ];


    return (
        <nav
            className="lg:mt-14 mt-8 flex overflow-x-auto no-scrollbar gap-2 lg:gap-5 px-4 py-2 justify-start items-center lg:text-xl text-lg font-semibold text-zinc-800 snap-x md:justify-center md:snap-none md:flex-wrap">
            {navigationItems.map((item) => (
                <NavigationItem
                    key={item.id}
                    route={item.route}
                >
                    {item.text}
                </NavigationItem>
            ))}
        </nav>
    );
}


const SUP1_1_3 = () => {
    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Demo Company Requests']}/>
            <NavigationBar/>
            <DemoCompanyPage/>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SUP1_1_3;
