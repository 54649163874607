import {useEffect, useState} from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import InputField from "../components/base/InputField";
import api from '../api'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentPage} from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import {
    fetchAllIndustries,
    fetchCreateBuildCompaniesBySupport
} from "../redux/thunks/companiesThunks";
import * as React from "react";
import SelectBox from "../components/base/SelectBox";
import Confirmation from "../components/Confirmation";
import PageLoader from "../components/PageLoader";
import {toast} from "react-toastify";
import TextareaField from "../components/base/TextareaField";
import {companySizeOptions, periodsOptions} from "../data/constants";

const SUP1_5_5 = ({children}) => {
    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    let [searchParams] = useSearchParams();
    const token = searchParams.get('token')

    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        industry: '',
        title: '',
        features: '',
        price: "",
        period: "",
    });

    const [industryOptions, setIndustryOptions] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false);
    const [errors, setErrors] = useState({});
    const [agree, setAgree] = useState(false);
    const [allPlans, setAllPlans] = useState(null)

    const getAllIndustries = async () => {
        const inds = await dispatch(fetchAllIndustries());
        setIndustryOptions([...inds.payload.map(el => ({
            label: el.name,
            value: el.id
        }))]);
    };

    useEffect(() => {
        getAllIndustries();
    }, [])

    const support = async () => {
        setIsLoading(true);
        const plansRes = await api.companies.getAllPricingPlans();
        if (plansRes?.success) {
            setAllPlans(plansRes?.data)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        support()
    }, [token, companySizeOptions]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setErrors(prevState => ({...prevState, [name]: false}))
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!agree) {
            toast.error("Please agree to the terms and conditions");
            return;
        }
        if (formData?.price?.length > 4) {
            toast.error("Price must be lover than 10000$");
            return;
        }


        setErrors({});
        let tempErrors = {};

        const fields = Object.entries(formData);
        fields.forEach(([key, value]) => {
            if (!value) {
                tempErrors[key] = "This field is required";
            }
        });

        if (!validateEmail(formData.email)) {
            tempErrors.email = 'Invalid email format';
        }

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            return;
        }

        try {
            let req;
            req = await dispatch(fetchCreateBuildCompaniesBySupport({
                ...formData,
                industry: industryOptions.find(el => el.name === formData.industry)?.label
            }));
            if (req?.payload?.success) {
                navigate(`/SUP1_1_4_1/${req?.payload?.data?.id}`)
            }


            if (!req?.payload?.success) {
                toast.error(`${req.payload.message}.  ${req.payload.message.includes("not found") ? "Please click the link in the email" : ""}`);
                return
            }

            if (req?.payload?.message) {
                toast.success(req.payload.message);
            }
        } catch (err) {
            toast.error("Error submitting the form.");
        }
    };


    const breadcrumbItems = [
        {name: 'Home', to: "/", isCurrent: false},
        {name: 'Support', to: "/sup01", isCurrent: false},
        {name: 'Create Company', to: "/sup1_5_5", isCurrent: true}
    ];

    return (<>
        <Breadcrumbs links={breadcrumbItems}/>

        {!isLoading && !message ? <section
            className="flex flex-col justify-between items-center px-8 md:px-16 py-9 md:py-11 bg-white rounded-md shadow-lg max-w-md:px-5 mt-[24px]">
            <header className="text-4xl md:text-5xl font-bold text-sky-950">
                Create Company
            </header>
            <form className="md:mt-11 mt-9 w-full" onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 gap-y-9 gap-x-[60px]">
                    <div>
                        <InputField label={getValue('form_work_email') || "Work Email"} type="email" name="email"
                                    id="email" value={formData.email}
                                    onChange={handleChange} error={errors.email}/>
                        <InputField label={getValue('form_first_name') || "First Name"} name="firstName" id="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange} error={errors.firstName}/>
                        <InputField label={getValue('form_last_name') || "Last Name"} name="lastName" id="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange} error={errors.lastName}/>
                        <InputField label={getValue('form_company_name') || "Company Name"} name="companyName"
                                    id="companyName"
                                    value={formData.companyName} onChange={handleChange} error={errors.companyName}/>
                        <SelectBox options={companySizeOptions} label={getValue('form_company_size') || "Company Size"}
                                   onSelect={val => {
                                       setFormData(prevState => ({...prevState, 'companySize': val}))
                                       setErrors(prevState => ({...prevState, 'companySize': false}));
                                   }}
                                   className={'!mt-[10px] !mb-[16px]'}
                                   value={formData.companySize} svg={2}
                                   error={errors.companySize}
                                   placeholder={"Choose Company Size"}
                                   labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}
                        />
                        <div className="grid lg:grid-cols-2 gap-x-5 !mt-[18px]">
                            <InputField label={getValue('form_job_title') || "Job Title"} name="jobTitle" id="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange} error={errors.jobTitle}/>
                            <SelectBox
                                options={industryOptions}
                                label={getValue('form_industry') || "Industry"}
                                onSelect={val => {
                                    setFormData(prevState => ({...prevState, 'industry': val}))
                                    setErrors(prevState => ({...prevState, 'industry': false}));
                                }}
                                className={'!mt-[7px] !mb-[16px]'}
                                value={formData.industry} svg={2}
                                error={errors.industry}
                                placeholder={"Choose Industry"}
                                labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}
                            />
                        </div>
                    </div>
                    <div>
                        {/*{pricingPlan?.features && <div*/}
                        {/*    className="flex flex-col gap-[16px] flex-1 mb-9 min-h-[175px]"*/}
                        {/*>*/}
                        {/*    <label htmlFor="email"*/}
                        {/*           className="text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]">Features</label>*/}
                        {/*    <div dangerouslySetInnerHTML={{__html: pricingPlan.features}}></div>*/}
                        {/*</div>}*/}
                        {/*{allPlans && <SelectBox*/}
                        {/*    options={allPlans?.map(plan => ({label: plan.title, value: plan.id})) || []}*/}
                        {/*    label="Pricing Plan"*/}
                        {/*    onSelect={val => {*/}
                        {/*        setNewPlan(allPlans?.find(plan => plan.id === val))*/}
                        {/*    }}*/}
                        {/*    className={'!mt-[10px] '}*/}
                        {/*    value={newPlan?.id} svg={2}*/}
                        {/*    labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}*/}
                        {/*/>}*/}
                        <InputField label={getValue('form_plan_title') || "Title"} name="title"
                                    id="title"
                                    value={formData.title} onChange={handleChange} error={errors.title}/>
                        <InputField label={getValue('form_plan_price') || "Price"} name="price"
                                    id="price"
                                    type={"number"}
                                    value={formData.price} onChange={handleChange} error={errors.price}/>
                        {/*<SelectBox*/}
                        {/*    options={periodsOptions}*/}
                        {/*    label="Period"*/}
                        {/*    onSelect={val => {*/}
                        {/*        setPeriod(val)*/}
                        {/*    }}*/}
                        {/*    className={'!mt-[10px] '}*/}
                        {/*    value={period} svg={2}*/}
                        {/*    labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}*/}
                        {/*/>*/}
                        <SelectBox options={periodsOptions} label={'Period'}
                                   onSelect={val => {
                                       setFormData(prevState => ({...prevState, 'period': val}))
                                       setErrors(prevState => ({...prevState, 'period': false}));
                                   }}
                                   className={'!mt-[10px] !mb-[16px]'}
                                   value={formData.period} svg={2}
                                   error={errors.period}
                                   placeholder={"Choose Plan Period"}
                                   labelClassName={'text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]'}
                        />

                        <TextareaField
                            wrapperClassName={"mt-5"}
                            label="Features"
                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                            name="features" id="features" placeholder="Features"
                            disabled={isLoading}
                            value={formData?.features} onChange={handleChange}
                            error={errors?.features}
                        />


                    </div>
                </div>
                <div className="flex justify-center flex-col items-center">
                    <div className="flex justify-center mt-8">
                        <input
                            type="checkbox"
                            id="agreement"
                            className="hidden"
                            onChange={(e) => {
                                setAgree(e.target.checked);
                            }}
                            checked={agree}
                        />
                        <label htmlFor="agreement" className="flex items-center cursor-pointer gap-5 md:gap-4">
                        <span
                            className={`flex h-[28px] w-[58px] items-center rounded-full aspect-square p-1 transition-all ${agree ? 'bg-[#37B34A]' : 'bg-gray-300'}`}
                            aria-hidden="true"
                        >
                            <span
                                className={`h-[20px] w-[20px] rounded-full transition-all ${agree ? 'ml-[30px] bg-white' : 'bg-[#37B34A]'}`}
                            ></span>
                        </span>
                            <span className="ml-2 text-[16px] text-zinc-800 flex-1 max-md:leading-[22px]">
                            By selecting this, you agree to the
                            <a href="/privacy" className="text-blue-500"> Privacy Policy </a> &
                            <a href="/terms" className="text-blue-500"> Terms of Services</a>
                        </span>
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="mt-5 h-[60px] md:h-16 text-[19px] md:text-2xl font-bold text-white uppercase bg-[#37B34A] rounded-md px-[5px] md:px-[65px] hover:bg-[#002E67] justify-center max-md:w-full inline-flex items-center"
                    >
                        {getValue('form_button_text_complete') || 'Hire better and faster!'}
                    </button>
                </div>
            </form>
        </section> : null}
        {!message && isLoading &&
            <PageLoader color="light"/>
        }
        {message && <Confirmation colorStyle="white" text={message}/>}
        {children ? children : null}


    </>);
}

export default SUP1_5_5;
