import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from "react";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useNavigate, useOutletContext} from 'react-router-dom';
import {array, number, object, string} from "yup";
import api from "../../../api";
import {FitScaleEvaluationTable} from "../../../components/base/FitScaleEvaluationTable";
import InputError from "../../../components/base/InputError";
import {NextStepSectionSBM01} from '../base/NextStepSectionSBM01';
import InputField from "../../../components/base/InputField";
import Checkbox from "../../../components/base/Checkbox";
import PrimaryButton from "../../../components/base/PrimaryButton";
import {getApplicationByIdFetch, getJobByIdFetch} from "../../../redux/thunks/jobsThunks";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

const Form = ({index, register, control, errors, handleRemove}) => {

    return (
        <section className="pt-[60px] border-t border-t-[#DBDBDB] flex justify-between gap-[20px] max-md:flex-col">
            <div className="max-w-[800px] w-full">
                <h2 className="font-bold text-[22px] mb-[24px]">Milestone {index + 1}:</h2>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">What is it</span>
                    <div className="max-w-[652px] w-full">
                        <input
                            type="text"
                            className="grey-input-custom"
                            {...register(`milestones.${index}.whatIsIt`)}
                        />
                        <InputError message={errors?.[index]?.whatIsIt?.message}/>
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span
                        className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">How to measure</span>
                    <div className="max-w-[652px] w-full">
                        <input
                            type="text"
                            className="grey-input-custom"
                            {...register(`milestones.${index}.howToMeasure`)}
                        />
                        <InputError message={errors?.[index]?.howToMeasure?.message}/>
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">Timeline</span>
                    <div className="max-w-[652px] w-full">
                        <input
                            type="date"
                            className="grey-input-custom"
                            placeholder="04/06/2023"
                            {...register(`milestones.${index}.timeline`)}
                        />
                        <InputError message={errors?.[index]?.timeline?.message}/>
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span
                        className="text-[16px] max-md:mb-[20px] md:text-xl font-semibold w-[208px]">Achieved or Not</span>
                    <Controller
                        name={`milestones.${index}.achieved`}
                        control={control}
                        render={({field}) => <FitScaleEvaluationTable
                            labelLow="Poor fit"
                            labelHigh="Perfectly Fit"
                            value={field.value}
                            onChange={(achieved) => field.onChange(achieved)}
                        />}
                    />

                </div>
            </div>
            <button
                className="text-lg font-bold text-white flex items-center h-[52px] justify-center px-9 py-5 bg-[#D70000] rounded-md max-md:px-5 max-md:w-full max-md:mt-[24px]"
                onClick={handleRemove}
            >
                Remove
            </button>

        </section>
    )
}


const TrainingStartsSection = ({localPayload, setLocalPayload, outsideBusinessHours, setOutsideBusinessHours}) => {
    const timeSlots = outsideBusinessHours
        ? [
            "12:00 am", "12:30 am", "01:00 am", "01:30 am", "02:00 am", "02:30 am",
            "03:00 am", "03:30 am", "04:00 am", "04:30 am", "05:00 am", "05:30 am",
            "06:00 am", "06:30 am", "07:00 am", "07:30 am", "08:00 am", "08:30 am",
            "09:00 am", "09:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am",
            "12:00 pm", "12:30 pm", "01:00 pm", "01:30 pm", "02:00 pm", "02:30 pm",
            "03:00 pm", "03:30 pm", "04:00 pm", "04:30 pm", "05:00 pm", "05:30 pm",
            "06:00 pm", "06:30 pm", "07:00 pm", "07:30 pm", "08:00 pm", "08:30 pm",
            "09:00 pm", "09:30 pm", "10:00 pm", "10:30 pm", "11:00 pm", "11:30 pm",
        ]
        : [
            "09:00 am", "09:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am",
            "12:00 pm", "12:30 pm", "01:00 pm", "01:30 pm", "02:00 pm", "02:30 pm",
        ];

    useEffect(() => {
        if (localPayload?.times) {
            const formattedSlots = timeSlots.map(timeFrom12To24Format);
            const hasMissingTimes = localPayload.times.some(time => !formattedSlots.includes(time));

            if (hasMissingTimes && !outsideBusinessHours) {
                setOutsideBusinessHours(true);
            }
        }
    }, []);

    const timeFrom12To24Format = (time) => {
        const [hours, minutes] = time.match(/^(\d+):(\d+)/).slice(1).map(Number);
        const period = time.match(/(am|pm)$/i)[0];
        const adjustedHours = period.toLowerCase() === "pm" && hours !== 12 ? hours + 12 : hours % 12;
        return `${String(adjustedHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    };

    const handleTimeSelection = (time) => {
        setLocalPayload((prev) => ({
            ...prev,
            times: prev?.times?.includes(time)
                ? prev?.times.filter((item) => item !== time)
                : [...(prev?.times || []), time],
        }));
    };

    return (
        <section className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <h2 className="font-bold text-[28px] md:text-3xl">Training Starts</h2>
            <div className="flex flex-col mt-8">
                <h3 className="mb-4 font-semibold text-zinc-800">Select available start times</h3>
                <InputField
                    type="date"
                    inputBg="bg-white border border-solid border-[#DEDED9]"
                    value={localPayload?.date || ""}
                    onChange={(e) => setLocalPayload((prev) => ({...prev, date: e.target.value}))}
                />
                <div className="flex gap-4 items-center mt-4">
                    <Checkbox
                        checked={outsideBusinessHours}
                        onChange={(value) => setOutsideBusinessHours(value)}
                    />
                    <div>Show outside ‘business hours’</div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 mt-6">
                    {timeSlots.map((time, index) => {
                        const formattedTime = timeFrom12To24Format(time);
                        return (
                            <button
                                key={index}
                                className={`p-3 border rounded ${localPayload?.times?.includes(formattedTime)
                                    ? "bg-green-500 text-white"
                                    : "bg-white text-gray-800"
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTimeSelection(formattedTime);
                                }}
                            >
                                {time}
                            </button>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};


const TrainingMilestones = () => {
    const navigate = useNavigate()
    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    const dispatch = useDispatch();
    useEffect(() => {
        setActiveStep(12)
    }, [])

    const schema = object({
        milestones: array().of(object({
            whatIsIt: string().required('This field is required'),
            howToMeasure: string().required('This field is required'),
            timeline: string().required('This field is required'),
            achieved: number().required('This field is required').min(0),
        })).required()
    });

    const {register, control, handleSubmit, formState: {errors}, setValue} = useForm({
        resolver: yupResolver(schema),
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "milestones",
        keyName: null
    });

    const onSubmit = async (data) => {
        console.log('FORM DATA', data.milestones);
        const created = [];
        const updated = [];
        data.milestones.forEach((item) => {
            if (item.id) {
                updated.push(item);
            } else {
                created.push(item);
            }
        })
        if (created.length) {
            const response = await api.trainingMilestones.createTrainingMilestones(applicationId, {
                "milestones": created
            })
            Promise.all([
                dispatch(getJobByIdFetch(jobId)),
                dispatch(getApplicationByIdFetch({applicationId, jobId}))
            ]);
            console.log(response);
        }
        if (updated.length) {
            const response = await api.trainingMilestones.updateTrainingMilestones(applicationId, {
                "milestones": updated
            })
            Promise.all([
                dispatch(getJobByIdFetch(jobId)),
                dispatch(getApplicationByIdFetch({applicationId, jobId}))
            ]);

            console.log(response);
        }
        toast.success('Milestones saved successfully!')
    };


    useEffect(() => {
        api.trainingMilestones.getAllTrainingMilestones(applicationId).then(response => {
            if (!response.success) {
                return;
            }
            setValue('milestones', response.data)
            // setMilestones(response.data);
        })
    }, [applicationId])


    const handlePrev = () => {
        navigate(`/SBM01_10_6/${jobId}/${applicationId}`)
    }


    const [outsideBusinessHours, setOutsideBusinessHours] = useState(false);
    const [localPayload, setLocalPayload] = useState(application?.assessments?.trainingStarts || null);
    const [isLoading, setIsLoading] = useState(false);


    const onSave = async () => {
        setIsLoading(true)
        if (!localPayload || !localPayload?.times.length || !localPayload.date) {
            setIsLoading(false);
            toast.error("Fill all fields");
            return
        }

        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    "trainingStarts": {
                        ...application?.assessments?.trainingStarts,
                        ...localPayload
                    }
                }
            })
            if (resp.success) {
                Promise.all([
                    dispatch(getJobByIdFetch(jobId)),
                    dispatch(getApplicationByIdFetch({applicationId, jobId}))
                ]);
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    };


    return (
        <div className='flex-auto'>
            <div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-12 mx-auto'>
                <TrainingStartsSection
                    localPayload={localPayload}
                    setLocalPayload={setLocalPayload}
                    outsideBusinessHours={outsideBusinessHours}
                    setOutsideBusinessHours={setOutsideBusinessHours}
                />
                <PrimaryButton
                    className={`w-full lg:w-[120px] !h-[52px] !text-lg`}
                    onClick={onSave}
                    isLoading={isLoading}
                >
                    Save
                </PrimaryButton>

                <section className=" ">
                    <h2 className="font-bold text-[28px] mdLtext-3xl">How to set up your milestones:</h2>
                    <div className="flex items-center mt-[32px]">
                        <span className="green-box"></span>
                        <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be measurable.</span>
                    </div>
                    <div className="flex items-center mt-[32px]">
                        <span className="green-box"></span>
                        <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be meaningful training progress</span>
                    </div>
                    <div className="flex items-center mt-[32px]">
                        <span className="green-box"></span>
                        <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must have a clear and reasonable timeline</span>
                    </div>

                </section>
                {fields.map((item, index) => <Form
                    key={index}
                    index={index}
                    register={register}
                    control={control}
                    errors={errors?.milestones}
                    handleRemove={async () => {
                        if (item.id) {
                            const response = await api.trainingMilestones.deleteTrainingMilestone(applicationId, item.id);
                            console.log(response);
                        }
                        remove(index);
                    }}
                />)}

                {/* {newMilestones.map((item, index) =>  <Form num={index + 1 + milestones.length}/>)} */}

                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            append({
                                "whatIsIt": "",
                                "howToMeasure": "",
                                "timeline": "",
                                "achieved": 1
                            })
                        }}
                        className="justify-center py-[15px] max-md:mt-[20px] max-md:flex-auto px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"
                        tabIndex="0">
                        Add a Milestone
                    </button>
                </div>
                <div className="flex justify-end w-full ">
                    <PrimaryButton
                        onClick={handleSubmit(onSubmit)}
                        className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                    >
                        {isLoading ? "Saving..." : "Save"}
                    </PrimaryButton>
                </div>
            </div>
            <NextStepSectionSBM01 prev={handlePrev}
                                  next={() => navigate(`/SBM01_10_14_2_1/${jobId}/${applicationId}`)}/>
        </div>
    )
}

export default TrainingMilestones
