import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SearchIcon from "../assets/img/search-icon.svg";
import Breadcrumbs from "../components/base/Breadcrumbs";
import {getSupportSolutionPagesFetch} from "../redux/thunks/solutionThunks";
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../components/PageLoader";
import {format} from "date-fns"
import getImageUrl from "../assets/helpers/getImageUrl";
import defaultImage from "../assets/img/No_Image_Available.jpg";

const formatTags = (tags, length = 2) => {
    return tags?.length ? tags.slice(0, length || tags.length).map(tag => tag?.tag?.name).join(", ") : ""
}

const SBM03 = () => {
    const {data, isLoading, categories} = useSelector(state => state.solution)
    const dispatch = useDispatch()

    const breadcrumbs = [
        {name: 'Subscriber Dashboard', to: "/SBM01"},
        {name: 'Support Center', to: "/SBM03", isCurrent: true}
    ]

    useEffect(() => {
        dispatch(getSupportSolutionPagesFetch())
    }, [])

    return (
        <>
            <div className="max-w-[1280px] overflow-hidden w-full px-5 mx-auto lg:pb-[150px] pb-20">
                <Breadcrumbs links={breadcrumbs}/>
                {
                    isLoading ?
                        <PageLoader color="light"/>
                        : data && data.length ?
                            <>
                                <ContentLibrary data={data} categories={categories}/>
                            </>
                            :
                            <h2>There is no solutions yet</h2>
                }
            </div>
            <div
                className="flex overflow-hidden flex-col items-center p-20 w-full min-h-[340px] max-lg:px-5 max-md:max-w-full z-[1px] relative bg-white">
                <section className="flex flex-col items-center py-8 w-full font-bold max-md:max-w-full">
                    <div
                        className="relative mt-2.5 lg:text-5xl text-4xl text-center leading-[44px] lg:leading-[60px] w-full">
                        Delight Your Valentine with the Gift of Free Time, Relaxation, and Joy!
                    </div>
                    <button
                        className="relative flex justify-center items-center px-16 py-6 mt-11 lg:mt-7 lg:text-2xl text-[19px] max-lg:w-full font-bold whitespace-nowrap hover:opacity-75 bg-[#37B34A] rounded-md w-[276px] max-md:px-5 h-[70px] text-white">
                        Contact Support
                    </button>
                </section>
            </div>
        </>
    )
}


function FilterButton({label, active, action}) {
    return (
        <button onClick={() => {
            action()
        }}
                className={active ? "grow justify-center px-[16px] py-[5px] font-semibold text-white bg-[#37B34A] rounded-md hover:opacity-70" : "self-stretch my-auto text-white hover:text-[#37B34A]"}>
            {label}
        </button>
    );
}


function PressReleaseCard({article}) {
    return (
        <Link to={`/SBM03_1/${article.id}`} className="flex flex-col grow text-xl leading-8 hover:opacity-90">
            <div className="block w-full">
                <img loading="lazy"
                     src={article.thumbailImage ? getImageUrl(article.thumbailImage) : defaultImage}
                     alt={"image"}
                     className="w-full aspect-square"
                     onError={(e) => (e.target.src = defaultImage)}

                />
            </div>
            <div className="flex gap-5 justify-between mt-6">
                <div className="font-bold text-green-500">
                    {article.category?.name}
                </div>
                <div className="font-light text-white">
                    {format(article.createdAt, "MMMM dd yyyy")}
                </div>
            </div>
            <p className="mt-4 text-4xl font-bold text-white leading-[50px]  line-clamp-2">
                {article?.name}
            </p>
            <div className="self-start mt-5 font-bold text-white whitespace-nowrap">
                {article?.user.firstName + " " + article?.user.lastName}
            </div>
            <div className="self-start text-white">
                {formatTags(article?.tags)}
            </div>
        </Link>
    );
}

function ContentLibrary({data, categories}) {
    const [searchValue, setSearchValue] = useState("");
    const [activeCategory, setActiveCategory] = useState(categories[0]?.id || null);
    const [searchParams, setSearchParams] = useState(['name', 'author'])
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData((prevData) => {
            return data.filter(item => {
                if (searchValue) {
                    return searchParams.some(param => {
                        return item?.category?.id === activeCategory && item[param]?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase())
                    })
                } else {
                    return item?.category?.id === activeCategory
                }
            });
        })
    }, [searchValue, activeCategory])

    return (
        <div className="flex flex-col items-center">
            <header
                className="flex justify-center items-center self-stretch pt-[84px] pb-[62px] w-full overflow-hidden">
                <div className="flex lg:gap-[32px] gap-4 w-full max-w-full max-lg:flex-wrap">
                    <h1 className="text-4xl font-bold leading-10 text-white whitespace-nowrap">Filter By</h1>
                    <nav
                        className="flex flex-auto lg:gap-3 gap-[22px] items-center self-start text-lg flex-wrap w-full">
                        <div className="relative w-full md:max-w-[180px]">
                            <img
                                loading="lazy" src={SearchIcon}
                                alt="Search Icon" className="w-6 aspect-square absolute right-[10px] top-[7px]"
                            />
                            <input
                                className="flex gap-5 justify-between px-[16px] py-[5px] bg-white rounded-md text-zinc-800 w-full"
                                placeholder="Search..."
                                value={searchValue}
                                onInput={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex items-center gap-4 whitespace-nowrap overflow-auto">
                            {
                                categories.map((item) => (
                                    <FilterButton label={item.name} key={item.id} active={activeCategory === item.id}
                                                  action={() => setActiveCategory(item.id)}/>
                                ))
                            }
                        </div>
                    </nav>
                </div>
            </header>
            <section className="max-w-full w-[1240px]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[40px] gap-y-11 lg:gap-y-[80px]">
                    {
                        filteredData.length ?
                            filteredData.map(article => (
                                <PressReleaseCard key={article.id} article={article}/>
                            ))
                            :
                            <p className="text-center md:col-span-3 text-white text-[30px]">No Results</p>
                    }
                </div>
            </section>
        </div>
    );
}

export default SBM03
